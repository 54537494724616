import { mapGetters, mapState } from 'vuex';
import { Providers } from '@flightscope/baseball-stats';
import { Handedness } from '@flightscope/baseball-stats/src/enums';
import STATES from '@/store/actions/states';
import PlayersFilters from '@/enums/PlayersFilters';
import TeamLogoUrl from '@/components/team/TeamLogoUrl.vue';
import PlayerAvatar from '@/components/player/PlayerAvatar.vue';
import HasPlayers from '@/components/mixins/HasPlayers';
import { EReportContext } from '@/enums/reports';
import NoData from '@/components/core/NoData.vue';
import FollowDialog from '@/modules/player-profiles/views/FollowDialog.vue';
import Fields from './helpers/fields';
import { SET_PLAYERS_SEARCH_FORCING } from '../../store/actions/players';

export default {
  name: 'PlayersList',

  metaInfo: {
    title: 'Players',
  },

  mixins: [HasPlayers],

  components: {
    TeamLogoUrl,
    PlayerAvatar,
    NoData,
    FollowDialog,
  },

  props: {
    ppLinkText: {
      type: String,
      default: 'Player Profile',
    },
  },

  data() {
    const messages = {
      headers: {
        name: 'Name',
        number: 'Number',
        position: 'Position',
        throwHandedness: Fields.Throws.label,
        batHandedness: Fields.Bats.label,
        BirthDate: 'Birthday',
        weight: Fields.Weight.label,
        height: Fields.Height.label,
        videos: 'Videos',
      },
      nodata: 'Player list is not available',
    };

    return {
      EReportContext,
      fixedHeader: true,
      filterChangeWatcher: null,
      headers: [
        { text: messages.headers.name, value: 'FormattedName', filter: this.nameFilter },
        { text: 'Team', value: 'team.displayName' },
        { text: messages.headers.number, value: 'Number', align: 'center' },
        {
          text: messages.headers.position, value: 'PrimaryPosition', align: 'center', filter: this.positionFilter
        },
        {
          text: messages.headers.batHandedness,
          value: 'Bats',
          align: 'center',
          filter: this.batHandednessFilter,
        },
        {
          text: messages.headers.throwHandedness,
          value: 'Throws',
          align: 'center',
          filter: this.throwHandednessFilter,
        },
        { text: 'Details', value: 'connectedTag', sortable: false },
        {
          text: '',
          value: 'actions',
          align: 'center',
        },
        // TODO: disabled for now after talking with PG
        // { text: messages.headers.BirthDate, value: 'BirthDate', align: 'center' },
        // { text: messages.headers.height, value: 'Height', align: 'center' },
        // { text: messages.headers.weight, value: 'Weight', align: 'center' },
        // TODO:enable when data will be send
        // {
        //   text: messages.headers.videos,
        //   value: 'VideoCount',
        //   align: 'center',
        //   filter: this.hasAnyVideosFilter
        // },
      ],
      page: 1,
      itemsPerPage: 20,

      showFollowDialog: false,
      player: null,
      sending: false,

      refreshCounter: 0,
      previousSearchPlayerFilter: {
        name: null,
        team: null,
        leagueId: null
      },
      previousPlayers: []
    };
  },

  asyncComputed: {
    players: {
      default() {
        return [];
      },
      get() {
        const {name, team} = this.filters;
        const leagueId = this.appFilters?.season?.leagueID;
        return this.getPlayers(name, team, leagueId);
      },
      watch: ['refreshCounter'],
    },
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem', 'isProfileLoaded']),
    ...mapGetters('entities/players', ['filters', 'forceFindPlayers']),
    ...mapGetters('app', { appFilters: 'filters' }),
    ...mapState({
      loading(state) {
        return state.entities.players.fetching || state.entities.teams.fetching;
      },
      isDataReady(state) {
        return state.user.status === STATES.SUCCESS;
      },

      disableViewActions() {
        return !this.isDataReady || this.loading;
      },
    }),
    Player() {
      return this.$store.$db().model('players');
    },
    states() {
      return STATES;
    },

    noDataMessage() {
      const { team, name } = this.filters;

      if (!team && !name) {
        return 'Please select a team or search by player name first.'
      }

      return 'No results found';
    },
  },

  beforeCreate() {
    this.Handedness = Handedness;
    this.EnumValueProvider = Providers.EnumValueProvider;
  },

  methods: {
    canFollow(item) {
      return this.$can('update', 'ConnectedTag') && item?.team?.followable && !item?.connectedTag;
    },

    filterColumn(key, selectedFilterValue) {
      if (!this.filters[key]) return true;
      return selectedFilterValue === this.filters[key];
    },

    nameFilter(value, search, item) {
      const filterName = this.filters.name;

      // If filter is not set yet
      if (!filterName) {
        return true;
      }

      const queryString = filterName.toLocaleLowerCase();

      if (item.FormattedName && typeof item.FormattedName === 'string' && item.FormattedName.toLocaleLowerCase().indexOf(queryString) > -1) return true;
      if (item.DisplayName && typeof item.DisplayName === 'string' && item.DisplayName.toLocaleLowerCase().indexOf(queryString) > -1) return true;
      if (item.FirstName && typeof item.FirstName === 'string' && item.FirstName.toLocaleLowerCase().indexOf(queryString) > -1) return true;
      if (item.LastName && typeof item.LastName === 'string' && item.LastName.toLocaleLowerCase().indexOf(queryString) > -1) return true;
      if (!/^[\d]+$/.test(queryString)) return false;
      if (item.Number == queryString) return true;
      if (item.TagID == queryString) return true;

      return false;
    },

    positionFilter(value, search, item) {
      return this.filterColumn(PlayersFilters.Position.key, item.PrimaryPosition);
    },

    batHandednessFilter(value, search, item) {
      return this.filterColumn(PlayersFilters.BatHandedness.key, item.BatHandedness);
    },


    throwHandednessFilter(value, search, item) {
      return this.filterColumn(PlayersFilters.ThrowHandedness.key, item.ThrowHandedness);
    },

    hasAnyVideosFilter(value, search, item) {
      return !search || value > 0;
    },

    filtersWatcher() {
      this.$nextTick(() => {
        this.page = 1;
      });
    },

    async getPlayers(name, team, leagueId) {
      if (
        !this.forceFindPlayers
        && this.previousSearchPlayerFilter.name === name
        && this.previousSearchPlayerFilter.team === team
        && this.previousSearchPlayerFilter.leagueId === leagueId
      ) {
        return this.previousPlayers;
      }
      await this.Player.dispatch(SET_PLAYERS_SEARCH_FORCING, {value: false});

      this.previousSearchPlayerFilter = { name, team, leagueId };
      if (name && !team) {
        let players = await this.PlayerRepo.getByName(name, true, leagueId);
        this.previousPlayers = players;
        return players;
      }

      if (team) {
        let players = await this.PlayerRepo.getForTeam(team, true);
        this.previousPlayers = players;
        return players;
      }

      return [];
    },

    onUpdateFollowed() {
      this.$emit('update:followed');
    },

    refresh() {
      this.refreshCounter++;
    },
  },
  watch: {
    filters: {
      handler: 'filtersWatcher',
      immediate: true,
      deep: true,
    },
  },
};
