import isFunction from 'lodash/isFunction';
import { mapGetters, mapActions, mapState } from 'vuex';
import startCase from 'lodash/startCase';
import { playerFromJson } from '@/models/PlayerModel';
import { PLAYER_REQUEST } from '@/store/actions/player';
import ResultFilter from '@/filters/ResultFilter';
import playerProfileLinksPerRouteProvider from '@/providers/PlayerProfileLinksPerRouteProvider';
import pitcherProfileDataParamsProvider from './providers/pitcherProfileDataParamsProvider';
import batterProfileDataParamsProvider from './providers/batterProfileDataParamsProvider';
import { EReportContext } from '@/enums/reports';

export default {
  name: 'PlayerProfile',
  metaInfo() {
    let title = `Player Profile ${this.id}`;
    return {
      title,
    };
  },

  inject: ['repo'],

  props: {
    id: {
      type: Number,
      default: 0,
    },
    context: {
      type: String,
    },
    reportType: {
      type: String,
    },
    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },
  },

  components: {
    Honeytrap: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/player-profiles/views/Honeytrap.vue'),
    DynamicPlayerFilters: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/player-profiles/filters/DynamicPlayerFilters.vue'),
    StaticPlayerFilters: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/player-profiles/filters/StaticPlayerFilters.vue'),
    PlayerProfileCard: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/player-profiles/profile/PlayerProfileCard.vue'),
    SelectedChips: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/video-library/components/SelectedChips.vue'),
    CareerStats: () => import(
      /* webpackChunkName: "playerProfiles" */
      '@/modules/player-profiles/profile/CareerStats.vue'),
  },
  data() {
    return {
      EReportContext,
      player: null,
      error: null,
      links: playerProfileLinksPerRouteProvider
        .getLinksForRoute('player')
        .filter(({
          header, text, disabled, can
        }) => {
          if (header) return true;
          if (can && isFunction(can)) return can();
          return !disabled;
        }),
      staticFilters: {
        // Needs to be null, setting it after profile watcher triggers getReport
        seasonFrom: null,
        seasonTo: null,
        selectedDatesRange: [],
        team: '',
        // TODO - enum
        gameType: null,
      },
      dynamicFilters: {},
      dataFiltersHeight: 160,
      rankingStats: {
        batting: [],
        pitching: [],
      },

      dateFilterRange: { min: null, max: null, },
    };
  },

  computed: {
    canSeeReports() {
      return this.$can('read', 'PlayerReport');
    },
    canSeeVideo() {
      return this.$can('read', 'Video');
    },
    videoContext() {
      return this.reportType == 'videos';
    },

    routeKey() {
      return `$player-${this.id}`;
    },

    reportRoutekey() {
      return [
        this.$route.name,
        this.$route.params.id,
        this.$route.params.context,
        this.$route.params.reportType
      ].join('-');
    },

    ...mapState('playerProfiles', {
      allProfiles: 'profiles',
    }),
    ...mapGetters('playerProfiles', {
      getProfileById: 'getProfileById',
      getCurrentProfileStatus: 'getCurrentProfileStatus',
    }),
    ...mapGetters(['selectedUnitsSystem']),

    storeStatus() {
      return this.getCurrentProfileStatus(this.id);
    },

    storeProfile() {
      return this.getProfileById(this.id).data;
    },

    currentReport() {
      const { context, reportType } = this.$route.params;
      return this.links.find(
        (link) => link.params && link.params.context === context && link.params.reportType === reportType,
      );
    },
    view() {
      return [this.context, this.reportType].filter(v => v).join('-');
    },

    HitDataFilter() {
      return batterProfileDataParamsProvider(this.view);
    },

    PitchDataFilter() {
      return pitcherProfileDataParamsProvider(this.view);
    },

    reportRequestQuery() {
      if (!this.player) {
        return null;
      }
      return {
        Id: this.player.Id,
        HitDataFilter: this.HitDataFilter,
        PitchDataFilter: this.PitchDataFilter,
        Context: this.$route.params.context,
        Filters: this.staticFilters,
      };
    },

    componentName() {
      return ['Profile', this.view.split('-').map(startCase).join('')].join('');
    },

    contextFilters() {
      let contextFilter = '';
      let mappedFilters = {};
      switch (this.context) {
        case EReportContext.PITCHING.key:
          contextFilter = ResultFilter.Pitcher.filterKey;
          break;
        case EReportContext.BATTING.key:
          contextFilter = ResultFilter.Batter.filterKey;
          break;
        default:
        // NOOP
      }

      let runnerKey = ResultFilter.ScoringRunnersOn.filterKey;

      if (
        this.dynamicFilters.hasOwnProperty(runnerKey)
        && !Array.isArray(this.dynamicFilters[runnerKey])
        && this.dynamicFilters[runnerKey] !== ''
      ) {
        mappedFilters[runnerKey] = [this.dynamicFilters[runnerKey]];
      }
      return { [contextFilter]: this.id, ...this.dynamicFilters, ...mappedFilters };
    },

    watchedRoute() {
      return [this.context, this.reportType].join('-');
    },

    reportStyles() {
      return {
        '--filters-h': `${this.dataFiltersHeight}px`,
      };
    },

    showDummyData() {
      return !this.canSeeReports || (this.videoContext && !this.canSeeVideo);
    },

    globalSeason() {
      return this.$store.state.app.filters.season;
    },
  },

  created() {
    this.$log.debug('PlayerProfile Page was created.');
  },

  mounted() {
    this.$log.debug('PlayerProfile Page was mounted.');
    if (this.id === 0) {
      this.error = 'Invalid player tag.';
    }
  },

  methods: {
    ...mapActions('playerProfiles', {
      fetchPlayerFromApi: PLAYER_REQUEST,
    }),

    fetchPlayer(newValue, oldValue) {
      this.$log.debug(this.$options.name, newValue, this.storeStatus, this.id, this.storeProfile);

      if (newValue !== null) {
        this.setData(undefined, newValue);
      }
    },

    setDefaultDates() {
      const { startDate, endDate } = this.globalSeason;

      if (startDate) {
        this.staticFilters.seasonFrom = new Date(startDate).getFullYear();
        this.dateFilterRange.min = startDate;
      }
      if (endDate) {
        this.staticFilters.seasonTo = new Date(endDate).getFullYear();
        this.dateFilterRange.max = endDate;
      }
    },

    setDefaultFilters() {
        if (this.player && this.player.hasTeam && this.player.teams.length === 1) {
          this.staticFilters.team = this.player.teams[0].TeamID;
        }
    },

    setData(err, playerData) {
      if (err) {
        this.error = err.toString();
      } else if (playerData) {
        this.player = playerFromJson(playerData);
        this.setDefaultFilters();
      }
    },

    routeWatcher(val) {
      this.dynamicFilters = {};
    },

    onResize() {
      if (this.$refs.dataFilters) {
        this.dataFiltersHeight = this.$refs.dataFilters.clientHeight;
      }
    },

    async fetchData(newVal, oldVal) {
      this.$log.debug(this.$options.name, newVal, this.storeStatus, this.id, this.storeProfile);

      if (newVal && !this.storeProfile) {
        this.fetchPlayerFromApi(newVal);
      }

      this.rankingStats = await this.PlayerRepo.fetchRankingStats(newVal);
    },
  },

  watch: {
    id: {
      handler: 'fetchData',
      immediate: true,
    },

    storeProfile: {
      handler: 'fetchPlayer',
      immediate: true,
    },

    watchedRoute: {
      handler: 'routeWatcher',
      deep: true,
      immediate: true,
    },

    player: {
      handler: 'onResize',
      immediate: true,
    },

    globalSeason: {
      handler: 'setDefaultDates',
      deep: true,
      immediate: true,
    },
  },
};
