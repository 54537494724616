const Fields = Object.freeze({
  Bats: {
    label: 'Bats',
  },
  Throws: {
    label: 'Throws',
  },
  PrimaryPosition: {
    label: 'Primary position',
  },
  SecondaryPosition: {
    label: 'Secondary position',
  },
  Weight: {
    label: 'Weight',
  },
  Height: {
    label: 'Height',
  },
  BirthDate: {
    label: 'DOB',
  },
  Country: { label: 'Hometown' },
  School: { label: 'Commitment' },
  GraduationYear: { label: 'Graduation year' },
  LastPlayedTeam: { label: 'Last played team' },
});

export default Fields;
