import { mapGetters } from 'vuex';
import { Enums } from '@flightscope/baseball-stats';
import { PlayerModel } from '@/models/PlayerModel';
import { get } from 'lodash';
import Fields from '../helpers/fields';

export default {
  name: 'PlayerProfileCard',
  components: {
    PlayerAvatar: () => import('@/components/player/PlayerAvatar.vue'),
    Parameter: () =>
      import(
        /* webpackChunkName: "playerProfiles" */
        '@/modules/player-profiles/profile/Parameter.vue'
      ),
  },

  props: {
    player: {
      type: PlayerModel,
    },

    stats: {
      type: Object,
    },
  },

  data() {
    return {
      reportType: 'Basic',
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),

    ...mapGetters('playerProfiles', ['getReportByKey', 'reportAvailable', 'reportLoading']),

    reportKey() {
      if (this.player) {
        let key = [this.player.Id, this.reportType];
        return key.join('-');
      }
      return '';
    },

    currentReport() {
      if (this.reportKey) {
        return this.getReportByKey(this.reportKey);
      }
      return undefined;
    },

    positions() {
      return [
        { ...Fields.PrimaryPosition, value: this.player.PrimaryPosition, can: this.$can('read', 'Player') },
        { ...Fields.SecondaryPosition, value: this.player.SecondaryPosition, can: this.$can('read', 'Player') },
      ];
    },

    metrics() {
      return [
        { ...Fields.Bats, value: this.player.Bats || '-', can: this.$can('read', 'Player') },
        { ...Fields.Throws, value: this.player.Throws || '-', can: this.$can('read', 'Player') },

        {
          ...Fields.Weight,
          value: this.$options.filters.convertUnit(this.player.Weight, 'WEIGHT', this.selectedUnitsSystem.system, {
            noValueFallback: '-',
            fractionalDigits: 0,
          }),
          can: this.$can('read', 'Player'),
        },

        {
          ...Fields.Height,
          value: this.$options.filters.convertUnit(
            this.player.Height,
            'HEIGHT_SHORT_FTIN',
            this.selectedUnitsSystem.system,
            { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: false },
          ),
          can: this.$can('read', 'Player'),
        },
      ];
    },

    details() {
      return [
        { ...Fields.BirthDate,  value: this.player.BirthDay || '-', can: this.$can('read', 'Player') },
        { ...Fields.Country,  value: this.player.Place || '-', can: this.$can('read', 'Player') },
        { ...Fields.LastPlayedTeam, value: this.player.team.name || '-', can: this.$can('read', 'Player') },
        { ...Fields.School, value: this.player.School || '-', can: this.$can('read', 'Player') },
        { ...Fields.GraduationYear, value: this.player.GraduationYear || '-', can: this.$can('read', 'Player') },
      ];
    },

    radarStats() {
      return [
        {
          label: 'Top Exit Velo',
          value: this.getStat('batting', Enums.BattingStats.MaximumExitVelocity),
          can: this.$can('read', 'Player'),
        },
        {
          label: 'Top FB Velo',
          value: this.getStat('pitching', Enums.PitchingStats.MaximumFastballVelocity),
          can: this.$can('read', 'Player'),
        },
        {
          label: 'Avg CH Velo',
          value: this.getStat('pitching', Enums.PitchingStats.AverageOffSpeedVelocity),
          can: this.$can('read', 'PlayerReport'),
        },
        {
          label: 'Avg BB Velo',
          value: this.getStat('pitching', Enums.PitchingStats.AverageBreakingBallVelocity),
          can: this.$can('read', 'PlayerReport'),
        },
      ];
    },
  },

  mounted() {
    this.$log.debug('PlayerProfile Bio Card mounted.');
  },

  methods: {
    /**
     * @param {String} type
     * @param {Object} enumObj
     *
     * @returns {String|Number}
     */
    getStat(type, enumObj) {
      let parameters = get(this.stats, [type, 0, 'parameters']);
      let val;

      if (parameters?.length) {
        let parameter = parameters.find((p) => p.parameter === enumObj.key);

        val = parameter?.value;
      }

      return this.$options.filters.convertUnitNoSymbol(val, enumObj.type, this.selectedUnitsSystem.system, {
        noValueFallback: '-',
        fractionalDigits: 0,
        fractionalPadding: false,
      });
    },
  },
};
