import { mapGetters, mapActions } from 'vuex';
import { get } from 'vuex-pathify';
import { Enums, Mappers, Providers, Formatters } from '@flightscope/baseball-stats';
import { GenericStrikeZone } from '@/enums/Generic';

import { EReportContext } from '@/enums/reports';

import { mapOptionsToSelect } from '@/utils/mappers';
import ResultFilter from '@/filters/ResultFilter';
import { EPitchSet } from '@/filters/enums';

const SimpleSelect = () => import('@/components/ui/selection-controls/SimpleSelect.vue');
const VideoList = () => import('@/modules/video-library/components/VideoList.vue');
const ActionBottomSheet = () => import('../../video-library/components/ActionBottomSheet.vue');


// @see https://preview.uxpin.com/610a49e7651121dd62a78287adb7c86b021576cc#/pages/135434486/simulate/sitemap
export default {
  name: 'ProfileVideos',

  components: {
    SimpleSelect,
    VideoList,
    ActionBottomSheet,
  },

  provide: {
    shareEnabled: !!process.env.VUE_APP_SHARE_ENABLED,
  },

  inject: ['context', 'repo'],

  props: {
    shareEnabled: {
      type: Boolean,
      default: !!process.env.VUE_APP_SHARE_ENABLED,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    player: {
      type: Object,
    },
    reportKey: {
      type: String,
    },
    dynamicFilters: {
      type: Object,
    },
    staticFilters: {
      type: Object,
    },
    reportLoading: {
      type: Boolean,
    },
    report: {
      type: Array,
    },
    MediaRepo: {
      default() {
        return this.repo.get('media');
      },
    },
  },

  data() {
    return {
      videoDefaultThumb: '/img/temp/video-thumbnail-1.jpg',
      filters: {
        team: null,
        seasonFrom: null,
        seasonTo: null,
        selectedDatesRange: [],
      },

      // TODO: double check if context is ok
      contextItems: [
        { text: 'All', value: null },
        { text: EReportContext.PITCHING.label, value: EReportContext.PITCHING.key },
        { text: EReportContext.BATTING.label, value: EReportContext.BATTING.key },
      ],

      pitchSetItems: EPitchSet,

      handednessItems: mapOptionsToSelect(ResultFilter.BatterHandedness.key),

      // todo - inject
      resultsMapper: null,
      resultPairsProvider: null,
      strikeZoneModel: null,

      shareModal: false,
      showVideosDialog: false,

      internalActions: [],

      chipsHeight: null,

      sortedActions: [],
      deletedVideoIds: [],
    };
  },

  created() {
    const pitchMapper = new Mappers.TransposedResultsMapper(
      false,
      undefined,
      Enums.ResultType.Pitch.key,
      Enums.ResultData.Data.key,
    );
    const hitMapper = new Mappers.TransposedResultsMapper(
      false,
      undefined,
      Enums.ResultType.Hit.key,
      Enums.ResultData.Data.key,
    );

    this.resultsMapper = new Mappers.AggregatedResultsMapper(
      false, undefined, pitchMapper, hitMapper
    );

    this.resultPairsProvider = new Providers.ResultPairsProvider();
    this.strikeZoneModel = new GenericStrikeZone();
  },

  computed: {
    ...mapGetters('playerProfiles', ['getReportByKey', 'reportAvailable']),
    ...mapGetters('videoLibrary', {
      loading: 'loading',
      tagIdItems: 'videoLibraryPlayers',
    }),
    season: get('app/filters.season'),
    tagIdItemsWithVideos() {
      return this.tagIdItems.filter(t => t.VideoCount);
    },
    playersLoaded() {
      return { tagId: this.tagId, context: this.context, loaded: !this.loading.players, hasPlayers: this.tagIdItemsWithVideos.length > 0 };
    },
    disableFilters() {
      return !this.$route.params.tagId || this.loading.players || this.loading.report || this.loading.media;
    },
    basePosition() {
      return { text: this.$vuetify.lang.t('$vuetify.playersFilter.basePositionText'), value: null };
    },
    baseTeam() {
      return { text: this.$vuetify.lang.t('$vuetify.playersFilter.baseTeamText'), value: null };
    },

    teamItems() {
      return [];
    },

    currentReport() {
      if (this.reportKey) {
        return this.getReportByKey(this.reportKey);
      }
      return undefined;
    },

    currentVideos() {
      const actionVideos = [];
      if (!this.player?.Id) {
        return actionVideos;
      }
      const mediaCollection = this.$store.state.videoLibrary.mediaCollection.find(m => (m.tagId === this.player.Id));

      return mediaCollection ? mediaCollection.media.filter(m => !this.deletedVideoIds.includes(m.id)) : undefined;
    },

    filteredActions() {
      const actions = [];
      const videos = this.currentVideos;
      // todo - move to separate class
      // 1. get list of videos for tag

      if (!videos || !videos.length) {
        return actions;
      }

      // 2. get reporting data
      const resultsFlat = this.currentReport;
      if (!resultsFlat || !resultsFlat.hasOwnProperty('data') || !resultsFlat.data) {
        return actions;
      }

      // 3. transpose results
      const results = this.resultsMapper.map(this.currentReport.data);

      // 4. merge and apply formatting
      const formatted = Formatters.PlayerResultsWithVideoFormatter(results, this.strikeZoneModel, true);

      // 5. date time based filtering, as a default use season
      let dateFrom = this.filters?.seasonFrom ? new Date(Date.UTC(this.filters?.seasonFrom, 0, 1)) : null;
      let dateTo = this.filters?.seasonTo ? new Date(Date.UTC(this.filters?.seasonTo + 1, 0, 1) - 1) : null;

      if (this.filters?.selectedDatesRange instanceof Array) {
        // sorting to make it from, to range, no matter in which order user clicks on the date picker
        this.filters.selectedDatesRange.sort();

        if (this.filters.selectedDatesRange[0]) {
          dateFrom = new Date(this.filters.selectedDatesRange[0]);
        }
        if (this.filters.selectedDatesRange[1]) {
          dateTo = new Date(this.filters.selectedDatesRange[1]);
          dateTo.setHours(dateTo.getHours() + 24);
          // todo - maybe ms ?
          dateTo.setSeconds(dateTo.getSeconds() - 1);
        }
      }

      // 6. apply filtering and get ResultPairs (pitch &&/|| hit)
      const options = {
        // tagging context
        pitcherIds: [],
        pitcherHandedness: this.dynamicFilters?.pitcherHandedness ?? '',
        pitchResults: this.dynamicFilters?.pitchResults ?? [],
        pitchTypes: this.dynamicFilters?.pitchTypes ?? [],
        pitchSet: this.dynamicFilters?.pitchSet ?? '',
        batterIds: [],
        batterHandedness: this.dynamicFilters?.batterHandedness ?? '',
        zones: this.dynamicFilters?.zone ?? [],
        dateFrom,
        dateTo,
      };

      const pairs = this.resultPairsProvider.matchPairs(formatted, options);

      // 7. now let's do a cross-matching
      this.mapPairsToActions(pairs, actions);

      return actions;
    },

    eventDates() {
      // TODO: double check if dates are consistent with dates from result
      return this.currentReport && this.currentReport.data ? [...new Set(this.currentReport?.data.SessionCreateDate)] : [];
    },

    selectedAction() {
      if (this.$route.params.action && this.sortedActions?.length) {
        return this.sortedActions.find(act => act.id === this.$route.params.action);
      }
      return null;
    },

    bottomSheetVisible() {
      return !!(this.$route.params.action && this.selectedAction);
    },
  },

  methods: {
    ...mapActions('videoLibrary', ['fetchVideos']),

    shareAction(action) {
      this.shareModal = action;
    },

    mapPairsToActions(pairs, actions) {
      const videos = this.currentVideos;

      for (let i = 0; i < pairs.length; i++) {
        const pitch = pairs[i].getPitch();
        const hit = pairs[i].getHit();
        const action = pitch ?? hit;
        const id = [action?.SessionID, action?.ResultID].join('-');
        // search for video, todo - add lookup table
        const videosForAction = videos.filter(
          v => (
            (pitch && v.resultID === pitch[Enums.ResultData.Id.key]) ||
            (hit && v.resultID === hit[Enums.ResultData.Id.key])
          )
        );

        if (!videosForAction || !videosForAction.length) {
          continue;
        }

        actions.push({
          id,
          results: pairs[i],
          videos: videosForAction,
        });
      }
    },

    fetchPlayerVideos(newValue, oldValue) {
      if (newValue?.Id) {
        this.fetchVideos(newValue.Id);
      }
    },

    bottomSheetVisibilityChanged(newValue, oldValue) {
      if (!newValue && this.$route.params.action) {
        this.$router.push({ params: { action: null, vid: null, share: null } });
      }
    }
  },

  watch: {
    player: {
      handler: 'fetchPlayerVideos',
      immediate: true
    },
    bottomSheetVisible: {
      handler: 'bottomSheetVisibilityChanged',
    },
  },
};
