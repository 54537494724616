export default function checker(obj, keys) {
  if (typeof obj !== 'object') {
    return false;
  }

  if (typeof keys === 'string') {
    return obj.hasOwnProperty(keys) && obj[keys];
  }

  if (Array.isArray(keys) && keys.length) {
    return keys.reduce((acc, curr) => {
      if (curr) {
        return acc && obj.hasOwnProperty(curr) && obj[curr];
      }

      return acc;
    }, true);
  }
  return false;
}
