import { Providers } from '@flightscope/baseball-stats';
import { compositeHitData, compositePitchData } from '@/enums/ReportValues';

function enumNameMapper(enumObj, val, suffix = undefined, enumKey = 'name') {
  const avgKey = 'AVG(3)';
  if (val.includes(avgKey)) {
    return 'Average';
  }
  let out = Providers.EnumValueProvider.getValue(val, enumObj, null, null, enumKey);
  if (suffix) {
    out = `${out}${suffix}`;
  }
  return out;
}

function reportCategoryNameMapper(cat, index, arr, suffix = ' ') {
  return enumNameMapper(compositePitchData, cat, suffix);
}

function reportHitCategoryNameMapper(cat, index, arr, suffix = ' ') {
  return enumNameMapper(compositeHitData, cat, suffix);
}

function reportPitchCategoryNameMapper(cat, index, arr, suffix = ' ') {
  return enumNameMapper(compositePitchData, cat, suffix);
}

export { reportCategoryNameMapper, reportHitCategoryNameMapper, reportPitchCategoryNameMapper };
