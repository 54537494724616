import { mapGetters } from 'vuex';
import { sync } from 'vuex-pathify';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import vuetify from '@/plugins/vuetify';
import PlayersFilter from '@/enums/PlayersFilters';
import { concatAndDeDuplicate } from '@/utils/helpers';
import { EHandednessExtended } from '@/filters/enums';
import {
  SET_NAME,
  SET_FILTERS,
  SET_PLAYERS_SEARCH_FORCING
} from '@/store/actions/players';
import StoreSimpleSelect from '@/components/ui/selection-controls/StoreSimpleSelect.vue';
import PlayersProps from '@/modules/player-profiles/mixins/PlayersProps';
import PlayersSeasonFilter from '@/modules/player-profiles/filters/PlayersSeasonFilter.vue';
import HasTeams from '@/components/mixins/HasTeams';
import PlayerPositionOrder from '@/utils/PlayerPositionOrder';
import { AclEntities, AclPermissions } from '@/services/ability';
import { copyToClipboard } from '@/utils/helpers';

export default {
  name: 'PlayersFilter',

  components: {
    PlayersSeasonFilter,
    StoreSimpleSelect,
  },

  mixins: [PlayersProps, HasTeams],

  data() {
    return {
      menuProps: {
        height: 'auto',
        offsetY: true,
        maxHeight: 'auto',
      },
      basePosition: { text: vuetify.framework.lang.t('$vuetify.playersFilter.basePositionText'), value: '' },
      baseTeam: { text: vuetify.framework.lang.t('$vuetify.playersFilter.baseTeamText'), value: '' },
      clipboardFallbackInputVisible: false,
      clipboardSnackbarColor: 'green',
      clipboardSnackbarTimeout: 2000,
      clipboardSnackbarVisible: false,
      localName: '',

      fetchingTeams: false,
      teamsSelectOptions: [],
      selectedTeam: null,
    };
  },

  mounted() {
    const availableProps = {};
    Object.keys(PlayersProps.props).forEach(playerProp => {
      availableProps[playerProp] = this[playerProp] || this.filters[playerProp];
    });

    if (availableProps.name) {
      this.localName = availableProps.name;
    }

    if (!isEmpty(availableProps)) {
      this.Player.dispatch(SET_FILTERS, availableProps);
    }
  },

  computed: {
    Team() {
      return this.$store.$db().model('teams');
    },
    Player() {
      return this.$store.$db().model('players');
    },

    filters: sync('entities/players@filter'),

    ...mapGetters('entities/teams', ['allEventTeamIDs', 'teamsForSeason', 'publicTeamsForSeason', 'isFetching', 'wasFetched', 'wasFetchedManaged', 'isFetchingManaged']),
    ...mapGetters(['isProfileLoaded', 'isLeagueManagerForCurrentSeason', 'isMaintenanceUser']),

    globalSeason() {
      return this.$store.state.app.filters.season;
    },

    selectedTeamId() {
      return this.$store.state.playerProfiles.filter.team;
    },

    positionSelectOptions() {
      let players = this.Player
        .query()
        .where('PrimaryPosition', (value) => !!value)
        .get();

      let uk = [];

      if (players.length) {
        uk = Object.keys(groupBy(players, 'PrimaryPosition'));
      }

      if (!uk.length) {
        return [this.basePosition];
      }
      // Build proper structure with proper order
      const selectOptions = PlayerPositionOrder.reduce((accumulator, currentValue) => {
        if (!uk.includes(currentValue.key)) { return accumulator; }
        return accumulator.concat({ text: currentValue.name, value: currentValue.key });
      }, []);

      return [this.basePosition, ...selectOptions];
    },
    batHandednessSelectOptions() {
      return EHandednessExtended;
    },
    throwHandednessSelectOptions() {
      return EHandednessExtended;
    },
    filtersStore() {
      return 'entities/players@filter';
    },
    filter() {
      return PlayersFilter;
    },
    allDataAvailable() {
      return true;
    },

    watched() {
      return {
        wasFetchedManaged: this.wasFetchedManaged,
        isFetchingManaged: this.isFetchingManaged,
        id: this.globalSeason?.id,
      };
    },

    localNameRules() {
      const min = 3;

      const rules = [];

      const t = this.selectedTeam;

      let minRule = (v) => !v || (v && (isFinite(String(v).trim() || NaN) || (typeof v === "string" && v.length >= min))) || `A minimum of ${min} characters is required.`;

      if (t) {
        // NOOP - internal search
      } else {
        // maybe check type of v
        rules.push(minRule);
      }

      return rules;
    },
  },
  methods: {
    filterByName() {
      if (this.$refs.localName.validate() ) {
        this.Player.dispatch(SET_NAME, { value: this.localName });
        this.Player.dispatch(SET_PLAYERS_SEARCH_FORCING, { value: true });
      }
    },

    setName() {
      this.Player.dispatch(SET_NAME, { value: this.localName });
    },

    clearName() {
      if (this.filters.name) {
        this.Player.dispatch(SET_NAME, { value: '' });
      }
    },

    onResize() {
      // eslint-disable-next-line no-underscore-dangle
      this.$refs.itemSlider._data.scrollOffset = 0;
      // TODO: After update vuetify check if  within breakpoint with active slider changing resolution to one not require slider scrolls items to initial offset.
    },

    fullPageUrl() {
      const allowedFilterNames = ['name', 'season', 'team', 'position', 'batHandedness', 'throwHandedness'];
      const currentFilters = this.filters;

      const allowedFilters = Object.keys(currentFilters)
        .filter((key) => allowedFilterNames.includes(key) && currentFilters[key])
        .reduce((res, key) => Object.assign(res, { [key]: currentFilters[key] }), {});

      const resolved = this.$router.resolve({
        to: '/players',
        query: allowedFilters,
      });

      const { href } = resolved;
      const { host } = window.location;
      return host + href;
    },

    copyUrlToClipboard() {
      this.clipboardSnackbarVisible = true;
      copyToClipboard(this.fullPageUrl());
    },

    getTeamsSelectOptions(id) {
      const myTeams = this.teamsForSeason(id);
      const pubTeams = this.publicTeamsForSeason(id);

      let teams = [
        { id: '', displayName: 'Select a team' },
      ];

      if (myTeams.length) {
        teams = [
          ...teams,
          { header: 'My Teams' },
          ...myTeams,
        ];
      }


      if (pubTeams.length && this.$can(AclPermissions.list, AclEntities.PublicTeams)) {
        teams = [
          ...teams,
          { header: 'Public Teams' },
          ...pubTeams,
        ];
      }

      return teams;
    },

    async watchSeason({ id }, oldVal) {
      if (this.isFetchingManaged) {
        return;
      }

      if (id !== !oldVal?.id) {
        this.fetchingTeams = true;
        if (this.$can(AclPermissions.list, AclEntities.PublicTeams) && !this.isMaintenanceUser) {
          await this.TeamRepo.listPublic(id);
        }

        const teams = this.getTeamsSelectOptions(id);

        this.teamsSelectOptions = teams;
        this.fetchingTeams = false;
      }

      if (this.teamsSelectOptions.length && !this.teamsSelectOptions.some(opt => opt.id === this.filters.team)) {
        this.filters.team = this.teamsSelectOptions.find(t => t?.id)?.id;
        this.$log.debug('Set team to', this.filters.team);
      }

    },
  },

  watch: {
    watched: {
      handler: 'watchSeason',
      immediate: true,
      deep: true,
    },
  },
};
