import { Formatters } from '@flightscope/baseball-stats';
import { GenericStrikeZone } from '@/enums/Generic';

const playerResultFormatter = (results) => {
  if (!(results instanceof Array)) {
    return false;
  }

  let strikeZoneModel = new GenericStrikeZone();
  return Formatters.PlayerResultsFormatter(results, strikeZoneModel);
};

export default playerResultFormatter;
