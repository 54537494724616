<template>
  <v-stepper-content step="1">
    <h3 class="mb-5 mx-4">{{ 'Find player to track' }}</h3>

    <v-toolbar tile dense elevation="0" class="mb-3">
      <core-stupid-season-select
        v-bind="selectProps"
        :value="localSeason"
        @input="(val) => (localSeason = val)"
        label="Season"
      ></core-stupid-season-select>

      <v-autocomplete
        v-bind="selectProps"
        hide-selected
        item-value="id"
        item-text="displayName"
        v-model="teamId"
        hide-no-data
        no-data-text="There are no teams for this season"
        :items="teams"
        :disabled="!localSeason || teamsFetching || !players.length"
        :loading="teamsFetching"
        label="Teams"
        class="ml-4 flex-grow-0"
      ></v-autocomplete>
    </v-toolbar>

    <v-data-table
      :items-per-page.sync="rowsPerPage"
      :headers="tableHeaders"
      :items="players"
      :loading="disableViewActions"
      :hide-default-footer="disableViewActions || !players.length"
    >
      <template v-slot:[`item.AvatarURL`]="{ item }">
        <player-avatar :size="30" :src="item.AvatarURL"></player-avatar>
      </template>

      <template v-slot:[`item.PrimaryPosition`]="{ item }">
        <div>{{ item.PrimaryPosition | playerPosition }}</div>
      </template>

      <template v-slot:[`item.FormattedName`]="{ item }">
        <player-avatar :size="30" :src="item.AvatarURL"></player-avatar
        ><span class="ml-2">{{ item.FormattedName }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          text
          :disabled="disableViewActions"
          class="text-decoration-underline text-transform-none link--text cursor font-weight-regular"
          @click="$emit('update:step', { player: item, wizard: 2 })"
          >{{ $vuetify.lang.t(followText) }}
        </v-btn>
      </template>

      <template v-slot:no-data>
        <no-data :message="noPlayersText" padding="32px"></no-data>
      </template>
    </v-data-table>
  </v-stepper-content>
</template>

<script>
import HasTeams from '@/components/mixins/HasTeams';
import HasPlayers from '@/components/mixins/HasPlayers';
import PlayerAvatar from '@/components/player/PlayerAvatar.vue';
import Fields from '../helpers/fields';
const NoData = () => import('@/components/core/NoData.vue');

export default {
  name: 'StepOne',

  mixins: [HasTeams, HasPlayers],

  components: {
    PlayerAvatar,
    NoData,
  },

  props: {
    followText: {
      type: String,
      default: 'Track this player',
    },

    season: {
      type: Object,
    },
  },

  data() {
    return {
      selectProps: {
        dense: true,
        outlined: true,
        hideDetails: true,
      },
      teamId: null,

      rowsPerPage: 10,

      tableHeaders: [
        // { text: '', value: 'AvatarURL' },
        {
          text: 'Name',
          value: 'FormattedName',
        },
        {
          text: 'Position',
          value: 'PrimaryPosition',
          align: 'center',
          filterable: true,
        },
        {
          text: Fields.Bats.label,
          value: 'BatHandedness',
        },
        {
          text: Fields.Throws.label,
          value: 'ThrowHandedness',
          filterable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          filterable: true,
        },
      ],
    };
  },

  asyncComputed: {
    teams: {
      get() {
        if (this.localSeason?.id) {
          let t = this.TeamRepo.listFollowable(this.localSeason.id);
          return t;
        }
        return [];
      },
      default() {
        return [];
      },
      watch: ['localSeason'],
    },

    players: {
      get() {
        if (this.teamId) {
          return this.PlayerRepo.getForTeam(this.teamId, true);
        }
        return [];
      },
      default() {
        return [];
      },
      watch: ['teamId'],
    },
  },

  computed: {
    localSeason: {
      get() {
        if (this.season) {
          return this.season;
        }
        return this.globalSeason;
      },
      set(val) {
        this.$emit('update:season', val);
      },
    },
    teamsFetching() {
      return this.$store.state.entities.teams.fetching;
    },

    playersFetching() {
      return this.$store.state.entities.players.fetching;
    },

    globalSeason() {
      return this.$store.state.app.filters.season;
    },

    firstTeamId() {
      return this.teams.find((t) => t.id)?.id;
    },

    watched() {
      return {
        seasonId: this.localSeason?.id,
        firstTeamId: this.firstTeamId,
      };
    },

    noPlayersText() {
      let text = this.$vuetify.lang.t('$vuetify.noDataText');

      if (!this.localSeason) {
        return 'Please select season first.';
      }

      if (this.localSeason && !this.teams.length) {
        return 'No teams available for this season. Please change the selected season.';
      }

      if (!this.teamId) {
        return 'Please select team first.';
      }

      return text;
    },

    disableViewActions() {
      return !this.localSeason || this.teamsFetching || this.playersFetching;
    },
  },

  methods: {
    watchHandler({ seasonId, firstTeamId }, oldVal) {
      if (seasonId !== oldVal?.seasonId) {
        this.teamId = null;
      }
      if (seasonId && firstTeamId && firstTeamId !== oldVal.firstTeamId && !this.teamId) {
        this.teamId = firstTeamId;
      }
    },
  },

  mounted() {
    // this.season = this.season ?? this.globalSeason;
  },

  watch: {
    watched: {
      handler: 'watchHandler',
      immediate: true,
    },
  },
};
</script>

<style>
</style>
