<template>
  <v-dialog max-width="60em" v-if="showFollowDialog" v-model="showFollowDialog">
    <v-card>
      <follower-card v-if="!followed" :player="player"></follower-card>

      <v-card-actions class="pa-8" v-if="!followed">
        <v-spacer></v-spacer>
        <v-btn color="info" @click="sendFollowRequest" :disabled="sending" :loading="sending">{{
          $vuetify.lang.t(confirmText)
        }}</v-btn>
      </v-card-actions>

      <v-container v-else class="d-flex flex-column align-center">
        <v-icon color="#43af50" size="66">mdi-checkbox-marked-circle-outline</v-icon>

        <v-card-text class="text-center">
          <p class="font-weight-medium body-1 last-step-text" v-html="$vuetify.lang.t(successText)"></p>
        </v-card-text>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { Player } from '@/models/orm/Hierarchy';
import HasConnectedTags from '@/components/mixins/HasConnectedTags';
import FollowerCard from '@/modules/player-profiles/views/FollowerCard.vue';

const computed =  {
  confirmText() {
    return this.player.team.acceptTracking ? 'Track' : 'Send request to coach';
  },

  successText() {
    return this.player.team.acceptTracking ? 'Success' : 'Your request has been sent successfully. Wait for the approval.<br /> When it is approved, you will be able to observe the profile of the selected player.';
  },

  showFollowDialog: {
    /** @returns Boolean */
    get() {
      return this.value;
    },
    /** @param {Boolean} val */
    set(val) {
      this.$emit('input', val);
    },
  },

  processing: {
    /** @returns Boolean */
    get() {
      return this.sending;
    },
    /** @param {Boolean} val */
    set(val) {
      this.$emit('update:sending', val);
    },
  },
};

export default {
  mixins: [HasConnectedTags],
  components: {
    FollowerCard,
  },

  props: {
    value: Boolean,
    player: Player,
    sending: Boolean,
  },

  data() {
    return {
      followed: false,
    };
  },

  computed,

  methods: {
    async sendFollowRequest() {
      this.processing = true;

      const response = await this.ConnectedTagRepo.followPlayer(this.player);

      if (response) {
        this.$emit('update:followed');
        this.followed = true;
        // TODO: update profile in backend for list of tags
      } else {
        // TODO: some alert?
      }
      this.processing = false;
    },
  },
};
</script>

<style>
</style>
