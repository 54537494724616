import HasConnectedTags from '@/components/mixins/HasConnectedTags';
import StepOne from './StepOne.vue';
import FollowerCard from './FollowerCard.vue';

export default {
  name: 'FollowWizard',

  mixins: [HasConnectedTags],

  components: {
    StepOne,
    FollowerCard,
  },

  props: {
    ctaText: {
      type: String,
      default: 'Find Player to track',
    },
    backText: {
      type: String,
      default: 'Back',
    },
    confirmText: {
      type: String,
      default: 'Send request to coach',
    },
    successText: {
      type: String,
      default: 'Your request has been sent successfully. Wait for the approval.<br /> When it is approved, you will be able to observe the profile of the selected player.',
    },
  },

  data() {
    return {
      showFollowDialog: false,
      sending: false,

      wizard: 1,
      player: null,

      season: null,
    };
  },

  methods: {
    closeFollowDialog() {
      this.$emit('update-list');
      this.showFollowDialog = false;
    },

    async sendFollowRequest() {
      this.sending = true;

      const response = await this.ConnectedTagRepo.followPlayer(this.player);

      if (response) {
        this.wizard = 3;
        // TODO: update profile in backend for list of tags
      } else {
        // TODO: some alert?
      }
      this.sending = false;
    },

    updateStep(obj) {
      let { player, wizard } = obj;
      this.player = player;
      this.wizard = wizard;
    }
  },
};
