import { render, staticRenderFns } from "./FollowWizard.vue?vue&type=template&id=08dc1c22&scoped=true"
import script from "./FollowWizard.js?vue&type=script&lang=js&external"
export * from "./FollowWizard.js?vue&type=script&lang=js&external"
import style0 from "./FollowWizard.vue?vue&type=style&index=0&id=08dc1c22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08dc1c22",
  null
  
)

export default component.exports