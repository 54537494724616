import { Enums } from '@flightscope/baseball-stats';

const basicFilters = [
  // tagging context
  Enums.PitchData.Classification.key,
  Enums.PitchData.Result.key,
  Enums.PitchData.SwingHandedness.key,
  Enums.PitchData.Handedness.key,
  // scoring context
  Enums.PitchData.BeforePlayRunnersOn.key,
  Enums.PitchData.BeforePlayBalls.key,
  Enums.PitchData.BeforePlayStrikes.key,
  Enums.PitchData.BeforePlayOuts.key,
  Enums.PitchData.Speed.key,
  Enums.PitchData.Spin.key,
  Enums.PitchData.Guid.key,
  // TODO: purpose: scoring data in tooltip on player profile
  // Enums.PitchData.BeforePlayInning.key,
  // Enums.PitchData.BeforePlayInningIsTop.key,
  // new scoring
  Enums.ScoringData.PlateAppearanceResult.key,
  Enums.ScoringData.PitchCall.key,
];

function pitcherProfileDataParamsProvider(view) {
  let filters = [];
  switch (view) {
    case 'pitching-summary':
      filters = [
        ...basicFilters,
        Enums.PitchData.BreakH.key,
        Enums.PitchData.BreakVInd.key,
        Enums.PitchData.BreakV.key,
        Enums.PitchData.StrikeZoneFront.key,
        Enums.PitchData.SpinDirection.key,
        Enums.PitchData.ReleasePoint.key,
        Enums.PitchData.Extension.key,
        Enums.PitchData.BeforePlayPitchCount.key,
        Enums.PitchData.AfterPlayPlateAppearance.key,
      ];
      break;

    case 'pitching-release-point':
      filters = [
        ...basicFilters,
        Enums.PitchData.ReleasePoint.key,
      ];
      break;

    case 'pitching-release-extension':
      filters = [
        ...basicFilters,
        Enums.PitchData.ReleasePoint.key,
        Enums.PitchData.Extension.key,
      ];
      break;

    case 'pitching-pitch-location':
    case 'pitching-pitch-location-2':
      filters = [
        ...basicFilters,
        Enums.PitchData.StrikeZoneFront.key,
      ];
      break;

    case 'pitching-performance-tracking':
      filters = [
        ...basicFilters,
        Enums.PitchData.StrikeZoneFront.key,
        Enums.PitchData.Speed.key,
        Enums.PitchData.Spin.key,
        Enums.PitchData.LaunchV.key,
        Enums.PitchData.LaunchH.key,
        Enums.PitchData.BreakH.key,
        Enums.PitchData.BreakV.key,
        Enums.PitchData.BreakVInd.key,
        Enums.PitchData.StrikeZoneFront.key,
        Enums.PitchData.SpinDirection.key,
        Enums.PitchData.ReleasePoint.key,
        Enums.PitchData.Extension.key,
        Enums.PitchData.BeforePlayPitchCount.key,
        Enums.PitchData.AfterPlayPlateAppearance.key,
        Enums.PitchData.PFXX.key,
        Enums.PitchData.PFXZ.key,
        Enums.PitchData.ApproachSpeed.key,
        Enums.PitchData.ApproachV.key,
        Enums.PitchData.ApproachH.key,
      ];
      break;

    case 'pitching-pitch-movement':
      filters = [
        ...basicFilters,
        Enums.PitchData.BreakH.key,
        Enums.PitchData.BreakVInd.key,
      ];
      break;

    case 'batting-videos':
    case 'pitching-videos':
      filters = [
        ...basicFilters,
        Enums.PitchData.Mechanics.key,
        Enums.PitchData.Speed.key,
        Enums.PitchData.Spin.key,
        Enums.PitchData.StrikeZoneFront.key,
        Enums.PitchData.SpinDirection.key,
        Enums.PitchData.BreakH.key,
        Enums.PitchData.BreakVInd.key,
        Enums.PitchData.Extension.key,
        Enums.PitchData.Guid.key,
        Enums.ResultData.SessionId.key,
        Enums.ScoringData.PitchCall.key,
        Enums.ScoringData.PlateAppearanceResult.key,
      ];
      break;

    case 'batting-summary':
      filters = [
        ...basicFilters,
        Enums.PitchData.StrikeZoneFront.key,
        Enums.PitchData.BeforePlayPitchCount.key,
        Enums.PitchData.AfterPlayPlateAppearance.key,
      ];
      break;

    case 'batting-strike-zone':
    case 'batting-strike-zone-2':
      filters = [
        ...basicFilters,
        Enums.PitchData.StrikeZoneFront.key,
      ];
      break;

    case 'batting-spray-chart':
      filters = [
        ...basicFilters,
        Enums.PitchData.StrikeZoneFront.key,
      ];
      break;

    case 'batting-performance-tracking':
      filters = [
        // tagging context
        // [ResultData.BatterTagId.key],
        // [ResultData.TagId.key],
        ...basicFilters,
        // data params
        Enums.HitData.ExitSpeed.key,
        Enums.HitData.CarryDistance.key,
        Enums.HitData.LaunchV.key,
        Enums.HitData.LaunchH.key,
        Enums.HitData.LandingPoint.key,
        Enums.HitData.Guid.key,
      ];
      break;

    default:
      throw new TypeError(`Not implemented view: ${view}`);
  }
  return filters.join(',');
}

export default pitcherProfileDataParamsProvider;
