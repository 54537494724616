import { mapGetters } from 'vuex';
import { SET_SEASON } from '@/store/actions/players';

export default {
  name: 'PlayersSeasonFilter',
  data() {
    return {
      menuProps: {
        offsetY: true,
      },
    };
  },
  computed: {
    season: {
      get() {
        return this.$store.state.players.filter.season;
      },
      set(value) {
        this.$store.dispatch(`players/${SET_SEASON}`, { value });
      },
    },
    ...mapGetters({
      seasons: 'seasons',
    }),
  },
};
