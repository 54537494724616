import vuetify from '@/plugins/vuetify';
import { seasons } from '@/utils/helpers';

export default {
  name: 'SeasonRangeFilter',
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },

    seasonFrom: {
      type: Number,
      default: null
    },

    seasonTo: {
      type: Number,
      default: null
    },

    seasons: {
      type: Array,
      defult: seasons(),
    }
  },
  data() {
    return {
      menuProps: {
        offsetY: true,
      },
      seasonLabel: vuetify.framework.lang.t('$vuetify.playerFilters.seasonLabel'),
    };
  },
  computed: {
    seasonFromSelectOptions() {
      const selectOptions = this.seasons.filter((season) => season <= this.seasonTo || !this.seasonTo);
      return selectOptions;
    },
    seasonToSelectOptions() {
      const selectOptions = this.seasons.filter((season) => season >= this.seasonFrom || !this.seasonFrom);
      return selectOptions;
    },
  },
};
