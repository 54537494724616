<template>
  <v-data-table
    hide-default-header
    hide-default-footer
    disable-sort
    :headers="headers"
    :items="items"
    class="table rounded-0"
    :mobile-breakpoint="0"
  >
    <template v-slot:header="{ props }">
      <thead>
        <tr>
          <th-ext
            :title="item.title"
            v-for="(item, i) in props.headers"
            :key="item.key"
            :dfn="!!item.title"
            :align="item.align"
            class="th top-aligned"
            :class="hasManyLeagues && i === 0 ? 'fixed-width' : ''"
            >{{ item.text }}</th-ext
          >
        </tr>
      </thead>
    </template>

    <template #item.season="{ item }">
      <div class="d-flex flex-column">
        {{ item.season.displayName }}
        <small class="grey--text font-weight-thin league" v-if="hasManyLeagues">
          {{ item.season.league }}
        </small>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import ThExt from '@/components/ui/tables/ThExt.vue';

export default {
  name: 'CareerStatsTable',

  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    hasManyLeagues: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ThExt,
  },
};
</script>

<style lang="scss" scoped>
.th {
  background-color: #e9f8ff;
  height: 36px !important;
  white-space: nowrap;
}
.table {
  ::v-deep td {
    font-weight: bold;
    white-space: nowrap;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  ::v-deep td,
  ::v-deep th {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  ::v-deep thead > tr > th:nth-child(1):not(.fixed-width) {
    width: 8em;
  }

  ::v-deep tbody > tr > td:nth-child(1),
  ::v-deep thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }

  ::v-deep tbody > tr > td + td.sticky,
  ::v-deep thead > tr > th + th.sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 56px;
    z-index: 3;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  ::v-deep thead > tr > th + th.sticky {
    z-index: 1;
  }

  .fixed-width {
    width: 17em;
  }

  .league {
    word-break: break-word;
    white-space: normal;
  }
}
</style>
