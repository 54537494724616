<template>
  <v-container class="pa-5 follower-card d-flex">
    <player-avatar
      class="mr-12"
      :src="player.AvatarURL"
      :size="160"
      v-if="player && $can('read', 'Player')"
    ></player-avatar>

    <v-row v-if="player">
      <v-col cols="12" class="flex-grow-1">
        <p v-if="$can('read', 'Player')" class="player-name">{{ player.FormattedName }}</p>
      </v-col>

      <player-profile-field
        small
        class="mb-4 pb-4 profile-field"
        uppercase-label
        v-for="(field, i) in details.filter((field) => field.can)"
        :cols="4"
        :key="`field-${i}`"
        :label="field.label"
      >
        {{ field.value }}
      </player-profile-field>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayerAvatar from '@/components/player/PlayerAvatar.vue';
import { Player } from '@/models/orm/Hierarchy';
import PlayerProfileField from '@/modules/player-profiles/profile/PlayerProfileField.vue';
import Fields from '../helpers/fields';

export default {
  name: 'FollowerCard',

  components: {
    PlayerAvatar,
    PlayerProfileField,
  },

  props: {
    player: {
      type: [Player, null],
    },
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),

    details() {
      return [
        {
          ...Fields.PrimaryPosition,
          value: this.$options.filters.playerPosition(this.player.PrimaryPosition),
          can: this.$can('read', 'Player'),
        },
        {
          ...Fields.SecondaryPosition,
          value: this.$options.filters.playerPosition(this.player.SecondaryPosition),
          can: this.$can('read', 'Player'),
        },
        {
          ...Fields.Bats,
          value: this.$options.filters.handedness(this.player.BatHandedness),
          can: this.$can('read', 'Player'),
        },
        {
          ...Fields.Throws,
          value: this.$options.filters.handedness(this.player.ThrowHandedness),
          can: this.$can('read', 'Player'),
        },

        {
          ...Fields.Weight,
          value: this.$options.filters.convertUnit(this.player.Weight, 'WEIGHT', this.selectedUnitsSystem.system, {
            noValueFallback: '-',
            fractionalDigits: 0,
          }),
          can: this.$can('read', 'Player'),
        },

        {
          ...Fields.Height,
          value: this.$options.filters.convertUnit(
            this.player.Height,
            'HEIGHT_SHORT_FTIN',
            this.selectedUnitsSystem.system,
            { noValueFallback: '-', fractionalDigits: 2, fractionalPadding: false },
          ),
          can: this.$can('read', 'Player'),
        },

        { ...Fields.BirthDate, value: this.player.BirthDate || '-', can: this.$can('read', 'Player') },
        // TODO:figure out what is Place
        // { ...Fields.Country, value: this.player.Country || '-', can: this.$can('read', 'Player') },
        { ...Fields.School, value: this.player.School || '-', can: this.$can('read', 'Player') },
        { ...Fields.GraduationYear, value: this.player.GraduationYear || '-', can: this.$can('read', 'Player') },
        { ...Fields.LastPlayedTeam, value: this.player.team.displayName || '-', can: this.$can('read', 'Player') },
      ];
    },
  },
};
</script>

<style scoped>
.follower-card {
  max-width: 45em;
}
.player-name {
  font-size: 2.813rem;
}
.profile-field {
  position: relative;
}
.profile-field:after {
  content: '';
  display: block;
  border-bottom: 1px #f0f0f0 solid;
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
