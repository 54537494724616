export default {
  props: {
    name: {
      type: String,
    },
    season: {
      type: Number,
    },
    team: {
      type: Number,
    },
    position: {
      type: String,
    },
    throwHandedness: {
      type: String,
    },
    batHandedness: {
      type: String,
    },
  },
};
