import moment from 'moment';
import { dropdownIcon } from '@/utils/helpers';
import vuetify from '@/plugins/vuetify';
import { DATE_FMT } from '@/utils/date_n_time';

const DATE_PERIODS = {
  TODAY: 1,
  YESTERDAY: 2,
  LAST_7_DAYS: 3,
  LAST_30_DAYS: 4,
  THIS_MONTH: 5,
  LAST_MONTH: 6,
  CUSTOM_RANGE: 7,
};

export default {
  name: 'CustomDateRangeFilter',
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    seasonFrom: {
      type: Number,
      default: null,
    },
    seasonTo: {
      type: Number,
      default: null,
    },
    selectedDatesRange: {
      type: Array,
      required: true,
    },

    dateRange: {
      type: Object,
      default: () => ({
        min: null,
        max: null,
      }),
    },
  },
  data: () => ({
    opened: false,
    // Date ranges are stored locally. It's passed back to parent on clicking ok in menu and then starts new fetch.
    localSelectedDateRanges: [],
  }),
  computed: {
    textFieldTitle() {
      const { seasonFrom, seasonTo } = this;
      const allInTranslated = vuetify.framework.lang.t('$vuetify.playerFilters.allIn');
      if (this.localSelectedDateRanges.length === 0) {
        // If seasons was not set yet.
        if (!seasonFrom) {
          return '';
        }

        return seasonFrom !== seasonTo
          ? `${allInTranslated} ${seasonFrom} - ${seasonTo}`
          : `${allInTranslated} ${seasonFrom}`;
      }

      return [...this.localSelectedDateRanges].sort().join(' ~ ');
    },
    dateRangeOptions() {
      const today = moment();
      const yesterday = moment().subtract(1, 'days');
      const last7Days = moment().subtract(6, 'days');
      const last30Days = moment().subtract(29, 'days'); // last 29 days + current day = 30
      const startOfCurrentMonth = moment().startOf('month');
      const endOfCurrentMonth = moment().endOf('month');
      const startOfLastMonth = moment().subtract(1, 'months').startOf('month');
      const endOfLastMonth = moment().subtract(1, 'months').endOf('month');

      return [
        {
          period: DATE_PERIODS.TODAY,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.today'),
          dateRanges: [today.format(DATE_FMT.DATE)],
        },
        {
          period: DATE_PERIODS.YESTERDAY,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.yesterday'),
          dateRanges: [yesterday.format(DATE_FMT.DATE)],
        },
        {
          period: DATE_PERIODS.LAST_7_DAYS,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.last7Days'),
          dateRanges: [last7Days.format(DATE_FMT.DATE), today.format(DATE_FMT.DATE)],
        },
        {
          period: DATE_PERIODS.LAST_30_DAYS,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.last30Days'),
          dateRanges: [last30Days.format(DATE_FMT.DATE), today.format(DATE_FMT.DATE)],
        },
        {
          period: DATE_PERIODS.THIS_MONTH,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.thisMonth'),
          dateRanges: [startOfCurrentMonth.format(DATE_FMT.DATE), endOfCurrentMonth.format(DATE_FMT.DATE)],
        },
        {
          period: DATE_PERIODS.LAST_MONTH,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.lastMonth'),
          dateRanges: [startOfLastMonth.format(DATE_FMT.DATE), endOfLastMonth.format(DATE_FMT.DATE)],
        },
        {
          period: DATE_PERIODS.CUSTOM_RANGE,
          title: vuetify.framework.lang.t('$vuetify.playerFilters.customRange'),
          dateRanges: [],
        },
      ];
    },
    rangeMinimum() {
      if (this.dateRange.min) { return this.dateRange.min; }
      const start = moment(`${this.seasonFrom}`, DATE_FMT.DATE);
      const formattedStart = start.startOf('year').format(DATE_FMT.DATE);
      return formattedStart;
    },
    rangeMaximum() {
      if (this.dateRange.max) { return this.dateRange.max; }
      const end = moment(`${this.seasonTo}`, DATE_FMT.DATE);
      const formattedEnd = end.endOf('year').format(DATE_FMT.DATE);
      return formattedEnd;
    },
    datesRangeLabel() {
      return vuetify.framework.lang.t('$vuetify.playerFilters.datesRangeLabel')
    },

    appendIcon() {
      return dropdownIcon(this.opened);
    },

    showCurrent() {
      const t = moment().format();
      return t < this.rangeMaximum && t > this.rangeMinimum;
    },
  },
  methods: {
    changeSelectedDatesRange(option) {
      this.localSelectedDateRanges = option.dateRanges;
    },
    clickOk() {
      this.$emit('update:selected-dates-range', this.localSelectedDateRanges);
      this.opened = false;
    },
    clear() {
      this.localSelectedDateRanges = [];
      this.$emit('update:selected-dates-range', this.localSelectedDateRanges);
    },
    cancel() {
      this.opened = false;
    },
    seasonsChangedHandler() {
      this.localSelectedDateRanges = [];
    },
    blurHandler() {
      // If menu was closed, and local range differs from current.
      if (!this.opened && this.localSelectedDateRanges !== this.selectedDatesRange) {
        this.localSelectedDateRanges = this.selectedDatesRange;
      }
    },
  },
  watch: {
    seasonFrom: {
      handler: 'seasonsChangedHandler',
      immediate: true,
    },
    seasonTo: {
      handler: 'seasonsChangedHandler',
      immediate: true,
    },
    opened: {
      immediate: true,
      handler: 'blurHandler',
    },
  },
};
