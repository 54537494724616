var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"loading":_vm.disabled,"headers":_vm.tableHeaders,"items":_vm.followed,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('Are you sure you want to delete this item?')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:`item.FormattedName`,fn:function({ item }){return [_c('player-avatar',{attrs:{"size":30,"src":item.AvatarURL}}),_c('span',{staticClass:"ml-2"},[_c('player-profile-link',{attrs:{"id":item.playerTagID,"context":item.PrimaryPosition === 'P' ? _vm.EReportContext.PITCHING.key : _vm.EReportContext.BATTING.key}},[_vm._v(_vm._s(item.FormattedName)+" ")])],1)]}},{key:`item.PrimaryPosition`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.PrimaryPosition === 'UI' ? '-' : item.PrimaryPosition)+" ")]}},{key:`item.details`,fn:function({ item }){return [(item.status === _vm.states.PENDING)?_c('v-chip',{attrs:{"small":"","color":"info"}},[_vm._v(_vm._s(item.status))]):_vm._e(),([_vm.states.ACCEPTED, _vm.states.MANUAL, _vm.states.ENABLED].includes(item.status))?_c('router-link',{attrs:{"to":{
        name: 'player',
        params: {
          id: item.playerTagID,
          context: item.PrimaryPosition === 'P' ? _vm.EReportContext.PITCHING.key : _vm.EReportContext.BATTING.key,
          reportType: 'summary',
        },
      }}},[_vm._v(_vm._s(_vm.$vuetify.lang.t(_vm.ppLinkText)))]):_vm._e()]}},{key:`item.Bats`,fn:function({ item }){return [_vm._v(_vm._s(_vm._f("handedness")(item.Bats)))]}},{key:`item.Throws`,fn:function({ item }){return [_vm._v(_vm._s(_vm._f("handedness")(item.Throws)))]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","small":"","color":"warning","disabled":_vm.disabled || !!_vm.toBeDeleted,"loading":_vm.disabled || !!_vm.toBeDeleted},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.trash")])],1)]}},{key:"no-data",fn:function(){return [_c('no-data',{attrs:{"padding":"32px"}})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }