import { Providers, ValueObjects, Mappers } from '@flightscope/baseball-stats';
import ChartDataProvider from '@/modules/player-profiles/ChartDataProvider';
import { EReportContext } from '@/enums/reports';

export default {
  name: 'ProfileSummaryExpandable',

  components: {
    PlotlyGraph: () => import('@/components/ui/charts/plotly/PlotlyGraph.vue'),
  },

  props: {
    item: {
      type: Object,
    },

    headers: {
      type: Array,
    },

    unitSystem: {
      type: String,
      required: true,
    },

    player: {
      type: Object,
    },

    charts: {
      type: Array,
      default: () => [],
    },

    report: {
      type: Array,
      default: () => [],
    },

    dynamicFilters: {
      type: Object,
      default: () => {},
    },
  },

  inject: ['context', 'reportType'],

  data() {
    return {
      tab: null,

      chartOptions: {
        xaxis: {
          categories: [],
        },
      },

      calculatedCharts: undefined,

      loading: true,
      chartCounter: 0,

      expanded: [],
    };
  },

  computed: {
    watched() {
      return {
        charts: this.charts,
        report: this.report,
        dynamicFilters: this.dynamicFilters,
        unitSystem: this.unitSystem,
      };
    },

    maxMlbValues() {
      if (!this.player) {
        return undefined;
      }

      const comparisonObj = ValueObjects.ComparisonData.MLB.MLB2019;
      if (this.context === EReportContext.BATTING.key) {
        return comparisonObj.BatterPerformance;
      }
      if (this.player.Throws === 'L') {
        return comparisonObj.LHPPerformance;
      }
      return comparisonObj.RHPPerformance;
    },

    isMetric() {
      return this.unitSystem === 'METRIC';
    },

    filters() {
      if (this.player && this.item) {
        if (this.context === EReportContext.BATTING.key) {
          return { batterIds: [this.player.Id], pitchType: this.item.PitchType };
        }
        return { pitcherIds: [this.player.Id], pitchType: this.item.PitchType };
      }
      return {};
    },
  },

  methods: {
    loopHandler(key, config, chartSets = []) {
      const provider = new ChartDataProvider(key, config);

      let options = {
        isMetric: this.isMetric,
        unitSystem: this.unitSystem,
      };

      let { baseOnParams } = config;

      switch (key) {
        case 'pitching-pitchers-performance':
          if (!this.maxMlbValues) {
            return;
          }
          if (!this.maxMlbValues.hasType(this.item.PitchType)) {
            chartSets.push(undefined);
            return;
          }
          options = {
            ...options,
            providerConfig: [this.maxMlbValues, baseOnParams],
            providerAdditionalParams: [this.maxMlbValues],
          };
          break;

        case 'batting-batters-performance':
          if (!this.maxMlbValues) {
return;
          }
          // if (!this.maxMlbValues.hasType(this.item.PitchType)) {
          //   chartSets.push(undefined);
          //   return;
          // }
          options = {
            ...options,
            providerConfig: [this.maxMlbValues, baseOnParams],
            providerAdditionalParams: [this.maxMlbValues],
          };
          break;

        case 'pitching-pitchers-repeatability':
          options = {
            ...options,
            providerConfig: [baseOnParams],
          };
          break;

        case 'batting-plate-discipline':
          options = {
            ...options,
            providerConfig: baseOnParams,
          };
          break;
        default:
        // NOOP
      }
      let series = provider.getMappedSeries(this.report, { ...this.dynamicFilters, ...this.filters }, options);

      let props = provider.getProps(series, { unitSystem: this.unitSystem });

      chartSets.push(props);
    },

    watchedHandler(val, oldVal) {
      const { charts, report } = val;

      if (!charts || !report || !charts.length || !report.length || !this.player) {
        return;
      }

      this.calculatedCharts = undefined;

      let chartSets = [];

      this.loading = true;
      // eslint-disable-next-line no-restricted-syntax
      for (let { key, config } of charts) {
        config.playerName = this.player.FormattedName;
        this.loopHandler(key, config, chartSets);
      }
      this.calculatedCharts = chartSets.filter(val => val);

      this.loading = false;
    },
  },

  watch: {
    watched: {
      handler: 'watchedHandler',
      immediate: true,
    },
  },
};
