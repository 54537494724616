import { Enums } from '@flightscope/baseball-stats';

export const compositePitchData = Object.assign({}, Enums.PitchingStats, Enums.PitchData);

export const compositeHitData = Object.assign({}, Enums.BattingStats, Enums.HitData);

export const customReportValues = {
  PITCH_NUMBER: {
    key: 'PITCH_NUMBER', name: 'Pitch Number', type: 'Number', description: 'Number of pitch in set.'
  },
};

export default {
  ...compositePitchData,
  ...compositeHitData,
  ...customReportValues,
};
