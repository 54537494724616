import Selectable from '@/components/mixins/Selectable';
import FilterableSelector from '@/components/mixins/FilterableSelector';

export default {
  name: 'SimpleSelect',

  mixins: [Selectable, FilterableSelector],

  props: {
    itemKey: {
      type: String,
      default: 'text',
    },

    itemValue: {
      type: String,
      default: 'value',
    },

    // name: {
    //   type: String,
    //   required: true,
    // },

    label: {
      type: String,
      required: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menuProps: {
        offsetY: true,
        maxHeight: 'auto',
      },
      // items: [
      //   { text: 'All', value: '' },
      //   { text: 'Windup', value: 'WU' },
      //   { text: 'Stretch ', value: 'ST' },
      //   { text: 'Unidentified', value: 'UI' },
      // ],
    };
  },

  computed: {
    allItems() {
      return this.selected.length === this.options.length;
    },

    someItems() {
      return this.selected.length > 0 && !this.allItems;
    },
    // selectOptions() {
    //   const possible = this.possibleOptions;
    //   if (!(possible instanceof Array) ){
    //     return this.options;
    //   }

    //   return this.options.map( o => {
    //     o.disabled = !possible.includes(o.value);
    //     return o;
    //   });
    // },

    icon() {
      if (this.allItems) return 'mdi-close-box';
      if (this.someItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allItems) {
          this.selected = [];
        } else {
          this.selected = this.options.slice();
        }
      });
    },
  },
};
