<template>
<div class="parameter">
  <div class="caption grey--text" :class="{ 'text-uppercase': uppercaseLabel}">{{ label }}:</div>
  <div class="font-weight-bold" :class="{ 'body-2': small }"><slot></slot></div>
</div>
</template>

<script>
export default {
  name: 'Parameter',
  props: {
    label: String,
    icon: String,
    small: {
      type: Boolean,
      default: false
    },
    uppercaseLabel: {
      type: Boolean,
      default: false,
    },
  },
}

</script>

<style>

</style>
