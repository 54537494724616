import merge from 'lodash/merge';
import { Enums } from '@flightscope/baseball-stats';
import { Traces } from '@/components/ui/charts/plotly/PlotlyHelpers';
import { getColorForSet, PitchResultCombinedColorMapping } from '@/components/ui/charts/ColorMapping';
import { reportCategoryNameMapper, reportHitCategoryNameMapper, reportPitchCategoryNameMapper } from './reportCategoryNameMapper';
import pitchResultCombinedNameMapper from './pitchResultCombinedNameMapper';

export default function customizeTraceByContext(context, traces, config = {}) {
  let { marker, baseOnParams } = config;
  let setColors = marker && marker.length === traces.length;
  switch (context) {
    case 'batting-batters-performance':
      // convert obj x to custom data
      traces = traces.map((trace, i) => {
        let customdata = [];
        trace = { ...{}, ...Traces.generic.HBarTrace, ...trace };
        trace.x = trace.x.map(({ avg, max, percent, unit }) => {
          customdata.push({ avg, max, unit });
          return isNaN(percent) ? 0 : percent;
        });
        trace.customdata = customdata;
        trace.y = trace.y.map(reportHitCategoryNameMapper);
        trace.texttemplate = 'Avg: %{customdata.avg:.2f} %{customdata.unit}';
        if (setColors) {
          trace.marker = marker[i];
        }
        return trace;
      });
      traces.reverse();
      break;

    case 'pitching-pitchers-performance':
      // convert obj x to custom data
      traces = traces.map((trace, i) => {
        let customdata = [];
        trace = { ...{}, ...Traces.generic.HBarTrace, ...trace };
        trace.x = trace.x.map(({ avg, max, percent, unit }, i) => {
          customdata.push({ avg, max, unit });
          return isNaN(percent) ? 0 : percent;
        });
        trace.texttemplate = trace.x.map((t, j) => {
          const precision = Enums.PitchData.Spin.key === trace.y[j] ? 0 : 2;
          return `Avg: %{customdata.avg:.${precision}f} %{customdata.unit}`;
        });
        trace.customdata = customdata;
        trace.y = trace.y.map(reportCategoryNameMapper);
        if (setColors) {
          trace.marker = marker[i];
        }
        return trace;
      });
      traces.reverse();
      break;

    case 'batting-plate-discipline':
      traces = traces.map((trace, i) => {
        trace = { ...{}, ...Traces.generic.HBarTrace, ...trace };
        trace.x = trace.x.map(({ percent }) => (isNaN(percent) ? 0 : percent));
        trace.y = trace.y.map(reportHitCategoryNameMapper);
        trace.texttemplate = '%{x:.1f} %';
        if (setColors) {
          trace.marker = marker[i];
        }
        return trace;
      });
      traces.reverse();
      break;

    case 'pitching-pitchers-repeatability':
      traces = traces.map((trace, i) => {
        trace = { ...{}, ...Traces.generic.HBarTrace, ...trace };
        trace.x = trace.x.map(({ percent }) => (isNaN(percent) ? 0 : percent));
        trace.y = trace.y.map(reportCategoryNameMapper);
        trace.texttemplate = '%{x:.1f} %';
        if (setColors) {
          trace.marker = marker[i];
        }
        return trace;
      });
      break;

    case 'batting-spray-chart':
      traces = traces.map((trace) => {
        trace = merge({}, Traces.generic.ScatterTrace, trace);
        trace.name = pitchResultCombinedNameMapper(trace.key);
        trace.marker.color = getColorForSet(trace.key, PitchResultCombinedColorMapping );
        if (config.hover) {
          trace.hoverinfo = config.hover;
        }

        return trace;
      });
      break;

    case 'batting-performance-tracking':
      traces = traces.map((trace) => {
        trace.name = reportHitCategoryNameMapper(trace.key, undefined, undefined, false);
        if (trace.name === 'Average') {
          trace = merge({}, trace, Traces.generic.MovingAvgTrace);
        }
        return trace;
      });
      break;

    case 'pitching-performance-tracking':
      traces = traces.map((trace) => {
        trace.name = reportCategoryNameMapper(trace.key, undefined, undefined, false);
        if (trace.name === 'Average') {
          trace = merge({}, trace, Traces.generic.MovingAvgTrace);
        }
        return trace;
      });
      break;

    case 'pitching-plate-discipline':
      traces = traces.map((trace, i) => {
        trace = { ...{}, ...Traces.generic.HBarTrace, ...trace };
        trace.x = trace.x.map(({ percent }) => (isNaN(percent) ? 0 : percent));
        trace.y = trace.y.map(reportPitchCategoryNameMapper);
        trace.texttemplate = '%{x:.1f} %';
        if (setColors) {
          trace.marker = marker[i];
        }
        return trace;
      });
      traces.reverse();
      break;

    default:
    // NOOP
  }

  return traces;
}
