import { ChartTypeEnum } from './ChartHelpers';

const provider = (isMetric, chartType, strikeZoneModel) => {
  let xaxis = {};
  let yaxis = {};
  switch (chartType) {
    case ChartTypeEnum.RELEASE_POINT.type:
      xaxis = {
        isSymmetric: true,
        minRange: isMetric ? 4 : 13.2,
        padding: 0, //isMetric ? 0.2 : 0.6,
        gridSize: isMetric ? 0.25 : 1,
        sectorSize: isMetric ? 0.025 : 0.08333333,
      };
      yaxis = {
        isSymmetric: false,
        minRange: isMetric ? 2.43 : 8,
        padding: xaxis.padding,
        gridSize: xaxis.gridSize,
        sectorSize: xaxis.sectorSize,
        min: isMetric ? 0.5 : 1,
      };
      break;

    case ChartTypeEnum.PITCH_LOCATION.type:
      // ballDiameter: 0.07619999999999999;
      // maxHorizontalOffset: 1000;
      // maxVerticalOffset: 1000;
      // strikeZoneBottom: 0.4572;
      // strikeZoneLeft: -0.21589999999999998;
      // strikeZoneRight: 0.21589999999999998;
      // strikeZoneTop: 1.0668;

      // let strikeZoneWidth = strikeZoneModel.strikeZoneRight - strikeZoneModel.strikeZoneLeft;
      // let strikeZoneHeight = strikeZoneModel.strikeZoneTop - strikeZoneModel.strikeZoneBottom;
      // xaxis.minRange = strikeZoneWidth * 2;
      // let strikeZoneCenter = strikeZoneHeight / 2 + strikeZoneModel.strikeZoneBottom;
      // let chartBottom = strikeZoneCenter - strikeZoneHeight

      xaxis.isSymmetric = true;
      xaxis.extend = false;
      xaxis.minRange = isMetric ? 86.358 : 34;
      xaxis.padding = 0; //isMetric ? 10 : 4;
      xaxis.gridSize = isMetric ? 25 : 10;
      xaxis.sectorSize = isMetric ? 2.5 : 1;

      yaxis.extend = false;
      xaxis.sectorSize = isMetric ? 2.5 : 1;
      yaxis.min = isMetric ? 15.24 : 6;
      yaxis.minRange = (isMetric ? 121.92 : 48) + yaxis.min;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      yaxis.sectorSize = xaxis.sectorSize;
      break;

    case ChartTypeEnum.RELEASE_EXTENSION.type:
      xaxis.minRange = isMetric ? 2.5 : 8.2;
      xaxis.padding = isMetric ? 0.1 : 0.3;
      xaxis.gridSize = isMetric ? 0.2 : 0.5;
      xaxis.sectorSize = (isMetric ? 0.025 : 0.08333333) * 1;

      yaxis.minRange = isMetric ? 2.43 : 8;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      yaxis.sectorSize = xaxis.sectorSize;
      break;

    case ChartTypeEnum.PITCH_MOVEMENT.type:
      xaxis.isSymmetric = true;
      xaxis.extend = true;
      xaxis.minRange = isMetric ? 50 : 20;
      xaxis.padding = 0; //isMetric ? 5 : 2;
      xaxis.gridSize = isMetric ? 5 : 2.5;
      xaxis.sectorSize = isMetric ? 2.5 : 1;

      yaxis.isSymmetric = true;
      yaxis.extend = true;
      yaxis.minRange = xaxis.minRange;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      yaxis.sectorSize = xaxis.sectorSize;
      break;

    case ChartTypeEnum.SPRAY_CHART.type:
      xaxis.isSymmetric = true;
      xaxis.minRange = isMetric ? 140 * 1.05 : 460 * 1.05;
      xaxis.padding = 0;
      xaxis.gridSize = isMetric ? 10 : 25;
      xaxis.sectorSize = 1;

      yaxis.minRange = isMetric ? 122 * 1.05 : 400 * 1.05;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      yaxis.sectorSize = xaxis.sectorSize;
      break;

    case ChartTypeEnum.STRIKE_ZONE.type:
      // xaxis.isSymmetric = true;
      // xaxis.minRange = isMetric ? 300 : 120;
      // xaxis.padding = 0; //isMetric ? 10 : 4;
      // xaxis.gridSize = isMetric ? 25 : 10;
      // xaxis.sectorSize = isMetric ? 2.5 : 1;

      // yaxis.minRange = isMetric ? 150 : 60;
      // yaxis.padding = xaxis.padding;
      // yaxis.gridSize = xaxis.gridSize;
      // yaxis.sectorSize = xaxis.sectorSize;

      // copy from above pitch location
      xaxis.isSymmetric = true;
      xaxis.extend = false;
      xaxis.minRange = isMetric ? 86.358 : 34;
      xaxis.padding = 0; //isMetric ? 10 : 4;
      xaxis.gridSize = isMetric ? 25 : 10;
      xaxis.sectorSize = isMetric ? 2.5 : 1;

      yaxis.extend = false;
      xaxis.sectorSize = isMetric ? 2.5 : 1;
      yaxis.min = isMetric ? 15.24 : 6;
      yaxis.minRange = (isMetric ? 121.92 : 48) + yaxis.min;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      yaxis.sectorSize = xaxis.sectorSize;
      break;

    case ChartTypeEnum.PITCHER_PERFORMANCE.type:
    case ChartTypeEnum.BATTER_PERFORMANCE.type:
      // performance charts only padd Y axis
      break;

    default:
      throw new TypeError(`${chartType} - invalid chart type`);
    // NOOP;
  }
  return { xaxis, yaxis };
};

export default provider;
