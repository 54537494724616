<template>
  <v-data-table :loading="disabled" :headers="tableHeaders" :items="followed" hide-default-footer>
    <template v-slot:top>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">{{
            $vuetify.lang.t('Are you sure you want to delete this item?')
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:[`item.FormattedName`]="{ item }">
      <player-avatar :size="30" :src="item.AvatarURL"></player-avatar>
      <span class="ml-2">
        <player-profile-link
          :id="item.playerTagID"
          :context="item.PrimaryPosition === 'P' ? EReportContext.PITCHING.key : EReportContext.BATTING.key"
          >{{ item.FormattedName }}
        </player-profile-link>
      </span>
    </template>

    <template v-slot:[`item.PrimaryPosition`]="{ item }">
      {{ item.PrimaryPosition === 'UI' ? '-' : item.PrimaryPosition }}
    </template>

    <template v-slot:[`item.details`]="{ item }">
      <v-chip small v-if="item.status === states.PENDING" color="info">{{ item.status }}</v-chip>
      <router-link
        v-if="[states.ACCEPTED, states.MANUAL, states.ENABLED].includes(item.status)"
        :to="{
          name: 'player',
          params: {
            id: item.playerTagID,
            context: item.PrimaryPosition === 'P' ? EReportContext.PITCHING.key : EReportContext.BATTING.key,
            reportType: 'summary',
          },
        }"
        >{{ $vuetify.lang.t(ppLinkText) }}</router-link
      >
    </template>

    <template v-slot:[`item.Bats`]="{ item }">{{ item.Bats | handedness }}</template>
    <template v-slot:[`item.Throws`]="{ item }">{{ item.Throws | handedness }}</template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        icon
        small
        color="warning"
        :disabled="disabled || !!toBeDeleted"
        :loading="disabled || !!toBeDeleted"
        @click="deleteItem(item)"
      >
        <v-icon>$vuetify.icons.trash</v-icon>
      </v-btn>
    </template>

    <template v-slot:no-data>
      <no-data padding="32px"></no-data>
    </template>
  </v-data-table>
</template>

<script>
import HasConnectedTags from '@/components/mixins/HasConnectedTags';
import PlayerAvatar from '@/components/player/PlayerAvatar.vue';
import STATES from '@/store/actions/states';
import { EReportContext } from '@/enums/reports';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';
import Fields from '../helpers/fields';
const NoData = () => import('@/components/core/NoData.vue');

export default {
  name: 'FollowedList',

  mixins: [HasConnectedTags],

  components: {
    PlayerAvatar,
    PlayerProfileLink,
    NoData,
  },

  props: {
    followed: {
      type: Array,
      required: true,
    },

    deleteEvent: {
      type: String,
      default: 'unfollow-tag',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    ppLinkText: {
      type: String,
      default: 'Player Profile',
    },
  },

  data() {
    return {
      tableHeaders: [
        { text: 'Name', value: 'FormattedName' },
        // TODO: needs a team in model
        { text: 'Team', value: 'team.displayName' },
        { text: 'Number', value: 'Number' },
        { text: 'Position', value: 'PrimaryPosition' },
        { text: Fields.Bats.label, value: 'Bats' },
        { text: Fields.Throws.label, value: 'Throws' },
        { text: 'Details', value: 'details', sortable: false },
        { text: '', value: 'actions', align: 'end', sortable: false },
      ],

      toBeDeleted: null,

      dialogDelete: false,

      EReportContext,
    };
  },

  computed: {
    states() {
      return STATES;
    },
  },

  methods: {
    deleteItem(tag) {
      this.toBeDeleted = tag;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit(this.deleteEvent, this.toBeDeleted);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.toBeDeleted = null;
      });
    },
  },
};
</script>
