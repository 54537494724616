import { Enums } from '@flightscope/baseball-stats';

function batterProfileDataParamsProvider(view) {
  let filters = [];
  switch (view) {
    case 'batting-summary':
    case 'batting-performance-tracking':
      filters = [
        Enums.HitData.ExitSpeed.key,
        Enums.HitData.SwingHandedness.key,
        Enums.HitData.LaunchH.key,
        Enums.HitData.LaunchV.key,
        Enums.HitData.CarryDistance.key,
        Enums.HitData.LandingPoint.key,
        Enums.HitReprocessed.LandingSideR.key,
        Enums.HitReprocessed.LandingDistanceR.key,
      ];
      break;

    case 'batting-strike-zone':
    case 'batting-strike-zone-2':
      filters = [
        Enums.HitData.SwingHandedness.key,
      ];
      break;

    case 'batting-spray-chart':
      filters = [
        Enums.HitData.SwingHandedness.key,
        Enums.HitData.LandingPoint.key,
        Enums.HitData.ExitSpeed.key,
        Enums.HitData.LaunchV.key,
        Enums.PitchData.Speed.key,
        Enums.HitData.Guid.key,
        Enums.HitData.CarryDistance.key,
        Enums.HitReprocessed.LandingSideR.key,
        Enums.HitReprocessed.LandingDistanceR.key,
      ];
      break;

    case 'batting-videos':
    case 'pitching-videos':
      filters = [
        Enums.HitData.CarryDistance.key,
        Enums.HitData.ExitSpeed.key,
        Enums.HitData.LaunchV.key,
        Enums.HitData.SwingHandedness.key,
        Enums.HitData.Guid.key,
      ];
      break;
    case 'pitching-summary':
      filters = [
        Enums.HitData.ExitSpeed.key,
        Enums.HitData.SwingHandedness.key,
        Enums.HitData.LaunchH.key,
        Enums.HitData.LaunchV.key,
        Enums.HitData.CarryDistance.key,
        Enums.HitData.LandingPoint.key,
      ];
      break;
    case 'pitching-performance-tracking':
      filters = [
        Enums.HitData.ExitSpeed.key,
        Enums.HitData.SwingHandedness.key,
        Enums.HitData.LaunchH.key,
        Enums.HitData.LaunchV.key,
        Enums.HitData.CarryDistance.key,
        Enums.HitData.LandingPoint.key,
        Enums.HitData.Guid.key,
      ];
      break;
    default:
    // NOOP
    // TODO: maybe throw an error, but that requires cases for pitching views
  }
  return filters.join(',');
}

export default batterProfileDataParamsProvider;
