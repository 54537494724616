import PlayerPosition from '@/enums/PlayerPosition';

const PlayerPositionOrder = [
  PlayerPosition.Pitcher,
  PlayerPosition.Catcher,
  PlayerPosition.FirstBase,
  PlayerPosition.SecondBase,
  PlayerPosition.ThirdBase,
  PlayerPosition.ShortStop,
  PlayerPosition.LeftField,
  PlayerPosition.CenterField,
  PlayerPosition.RightField,
  PlayerPosition.Infielder,
  PlayerPosition.Outfielder,
  PlayerPosition.DesignatedHitter,
  PlayerPosition.UtilityPlayer,
  PlayerPosition.Unidentified,
];

export default PlayerPositionOrder;
