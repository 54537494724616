import map from 'lodash/map';

export default function calculateSeriesStats(context, initialSeries, convertDimensions) {
  if (initialSeries && initialSeries.length && convertDimensions && convertDimensions.length) {
    let stats = convertDimensions.reduce((acc, curr) => {
      let dimValues = initialSeries.getDimension(curr.dimension);

      if (typeof dimValues[0] === 'object') {
        dimValues = map(dimValues, 'percent');
      }

      acc[curr.dimension] = [];
      acc[curr.dimension].push(Math.min(...dimValues, 0));
      acc[curr.dimension].push(Math.max(...dimValues, 0));

      return acc;
    }, {});
    return stats;
  }
  return undefined;
}
