import { SET_TEAM, SET_POSITION, SET_BAT_HANDEDNESS, SET_THROW_HANDEDNESS } from '@/store/actions/players';

const PlayersFilters = {
  ThrowHandedness: {
    key: 'throwHandedness',
    name: 'Throw Handedness',
    action: `entities/players/${SET_THROW_HANDEDNESS}`,
  },
  BatHandedness: { key: 'batHandedness', name: 'Bat Handedness', action: `entities/players/${SET_BAT_HANDEDNESS}` },
  Team: { key: 'team', name: 'Teams', action: `entities/players/${SET_TEAM}` },
  Position: { key: 'position', name: 'Position', action: `entities/players/${SET_POSITION}` },
};

export default PlayersFilters;
