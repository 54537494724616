export default {
  name: 'PlayerProfileField',
  props: {
    label: String,
    icon: String,
    small: {
      type: Boolean,
      default: false
    },
    cols: {
      type: [String, Number],
      default: 6
    },
    uppercaseLabel: {
      type: Boolean,
      default: false,
    },
  },
}
