import vuetify from '@/plugins/vuetify';
import ResultFilter from '@/filters/ResultFilter';
import { EReportContext } from '@/enums/reports';
import BaseRouteProvider from './BaseRouteProvider';
import { ability, AclEntities, AclPermissions } from '../services/ability';
import { Enums } from '@flightscope/baseball-stats';

const scoringSelectors = [
  ResultFilter.ScoringOuts.key,
  ResultFilter.ScoringCounts.key,
  ResultFilter.ScoringRunnersOn.key,
];
const isSoftball = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key;

const linksPerRoutes = [
  {
    routeNames: ['player'],
    links: [
      // Pitching links
      { header: vuetify.framework.lang.t('$vuetify.playerFilters.pitchingSelectHeader') },
      {
        icon: '$vuetify.icons.list',
        id: 1,
        text: 'Pitching summary',
        params: { context: EReportContext.PITCHING.key, reportType: 'summary' },
        selects: [
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ...scoringSelectors,
        ],
        can: () => ability.can(AclPermissions.read, AclEntities.BasicPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartReleasePoint',
        id: 2,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.releasePoint'),
        params: { context: EReportContext.PITCHING.key, reportType: 'release-point' },
        selects: [
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ...scoringSelectors,
        ],
        can: () => ability.can(AclPermissions.read, AclEntities.AdvancedPlayerReport),
      },
      {
        icon: isSoftball ? '$vuetify.icons.chartReleaseExtensionSoftball' : '$vuetify.icons.chartReleaseExtension',
        id: 3,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.releaseExtension'),
        params: { context: EReportContext.PITCHING.key, reportType: 'release-extension' },
        selects: [ResultFilter.PlayOutcome.key, ResultFilter.PitchType.key, ...scoringSelectors],
        can: () => ability.can(AclPermissions.read, AclEntities.AdvancedPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartPitchMovement',
        id: 4,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.pitchMovement'),
        params: { context: EReportContext.PITCHING.key, reportType: 'pitch-movement' },
        selects: [ResultFilter.PlayOutcome.key, ResultFilter.PitchType.key, ...scoringSelectors],
        can: () => ability.can(AclPermissions.read, AclEntities.AdvancedPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartPitchLocation',
        id: 5,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.pitchLocation'),
        params: { context: EReportContext.PITCHING.key, reportType: 'pitch-location' },
        selects: [ResultFilter.PlayOutcome.key, ResultFilter.PitchType.key, ...scoringSelectors],
        can: () => false,
      },
      {
        icon: '$vuetify.icons.chartPitchLocation',
        id: 6,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.pitchLocation'),
        params: { context: EReportContext.PITCHING.key, reportType: 'pitch-location-2' },
        selects: [ResultFilter.PlayOutcome.key, ResultFilter.PitchType.key, ...scoringSelectors],
        can: () => ability.can(AclPermissions.read, AclEntities.BasicPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartPitcherPerformacnce',
        id: 7,
        text: vuetify.framework.lang.t('$vuetify.reports.pitcherPerformanceTracking'),
        params: { context: EReportContext.PITCHING.key, reportType: 'performance-tracking' },
        selects: [
          ResultFilter.PitchParameter.key,
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ...scoringSelectors,
        ],
        can: () => ability.can(AclPermissions.read, AclEntities.AdvancedPlayerReport),
      },
      {
        icon: 'mdi-video',
        id: 8,
        text: vuetify.framework.lang.t('$vuetify.reports.pitchingVideos'),
        params: { context: EReportContext.PITCHING.key, reportType: 'videos' },
        selects: [
          ResultFilter.PitcherHandedness.key,
          ResultFilter.BatterHandedness.key,
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ResultFilter.Zones.key,
          ResultFilter.PitchSet.key,
        ],
        can: () => ability.can(AclPermissions.read, 'Video'),
        hide: true,
      },

      // Batting links
      { header: vuetify.framework.lang.t('$vuetify.playerFilters.battingSelectHeader') },
      {
        icon: '$vuetify.icons.list',
        id: 9,
        text: 'Batting summary',
        params: { context: EReportContext.BATTING.key, reportType: 'summary' },
        selects: [
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ...scoringSelectors,
        ],
        can: () => ability.can(AclPermissions.read, AclEntities.BasicPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartPitchLocation',
        id: 10,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.strikeZone'),
        params: { context: EReportContext.BATTING.key, reportType: 'strike-zone' },
        selects: [ResultFilter.PlayOutcome.key, ResultFilter.PitchType.key, ...scoringSelectors],
        can: () => false,
      },
      {
        icon: '$vuetify.icons.chartPitchLocation',
        id: 11,
        text: vuetify.framework.lang.t('$vuetify.charts.heatmaps.strikeZone'),
        params: { context: EReportContext.BATTING.key, reportType: 'strike-zone-2' },
        selects: [ResultFilter.PlayOutcome.key, ResultFilter.PitchType.key, ...scoringSelectors],
        can: () => ability.can(AclPermissions.read, AclEntities.AdvancedPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartBatterSpray',
        id: 12,
        text: vuetify.framework.lang.t('$vuetify.reports.sprayChart'),
        params: { context: EReportContext.BATTING.key, reportType: 'spray-chart' },
        selects: [
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ...scoringSelectors,
        ],
        can: () => ability.can(AclPermissions.read, AclEntities.BasicPlayerReport),
      },
      {
        icon: '$vuetify.icons.chartBatterPerformance',
        id: 13,
        text: vuetify.framework.lang.t('$vuetify.reports.batterPerformanceTracking'),
        params: { context: EReportContext.BATTING.key, reportType: 'performance-tracking' },
        selects: [
          ResultFilter.HitParameter.key,
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ...scoringSelectors,
        ],
        can: () => ability.can(AclPermissions.read, AclEntities.AdvancedPlayerReport),
      },
      {
        icon: 'mdi-video',
        id: 14,
        text: vuetify.framework.lang.t('$vuetify.reports.battingVideos'),
        params: { context: EReportContext.BATTING.key, reportType: 'videos' },
        selects: [
          ResultFilter.PitcherHandedness.key,
          ResultFilter.BatterHandedness.key,
          ResultFilter.PlayOutcome.key,
          ResultFilter.PitchType.key,
          ResultFilter.Zones.key,
          ResultFilter.PitchSet.key,
        ],
        can: () => ability.can(AclPermissions.read, 'Video'),
        hide: true,
      },
    ],
  },
];

const playerProfileLinksPerRouteProvider = new BaseRouteProvider(linksPerRoutes);

export default playerProfileLinksPerRouteProvider;
