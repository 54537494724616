import startCase from 'lodash/startCase';
import { mapActions, mapGetters } from 'vuex';
import { Enums, Mappers } from '@flightscope/baseball-stats';
import { PLAYER_REPORT_REQUEST } from '@/store/actions/player';
import playerResultFormatter from '@/formatters/playerResultFormatter';
import { EReportContext } from '@/enums/reports'
import { dataProvider } from './Injectors';
import ChartDataProvider from './ChartDataProvider';
import { ReportsEnum } from './configs/ReportsEnum';

import * as unisportDummyReport from '@/dummyData/unisportDummyReport';

export default {
  name: 'ProfileReport',

  metaInfo() {
    let title = 'Player Profile';

    if (this.player) {
      title = `${title} ${this.player.Id}`;
    }

    title = `${title} - ${startCase(this.context)} ${startCase(this.reportType)}`;

    return {
      title,
    };
  },

  components: {
    // TODO: create proper components for charts
    ProfilePitchingSummary: () =>
      import(/* webpackChunkName: "playerProfilesPitching" */ '@/modules/player-profiles/reports/ProfilePitchingSummary.vue'),
    HeatmapReport: () =>
      import(/* webpackChunkName: "playerProfilesPitching" */ '@/modules/player-profiles/reports/HeatmapReport.vue'),
    ProfileBattingSummary: () =>
      import(/* webpackChunkName: "playerProfiles" */ '@/modules/player-profiles/reports/ProfileBattingSummary.vue'),
    ProfileVideos: () =>
      import(/* webpackChunkName: "playerProfiles" */ '@/modules/player-profiles/reports/ProfileVideos.vue'),
  },

  props: {
    player: {},
    componentName: {
      type: String,
    },
    context: {
      type: String,
      required: true,
    },
    reportType: {
      type: String,
      required: true,
    },

    staticFilters: {
      type: Object,
      default: () => {},
    },

    dynamicFilters: {
      type: Object,
      default: () => {},
    },
    query: {
      type: Object,
      default: () => {},
    },

    showDummyData: {
      type: Boolean,
      default: false,
    }
  },

  provide() {
    if (this.chartSettings) {
      return {
        [dataProvider]: ChartDataProvider,
      };
    }
    return { context: this.context, reportType: this.reportType };
  },

  data() {
    return {
      reload: 1,
    };
  },

  created() {
    this.$log.debug('player profile report was created');
  },

  mounted() {
    this.$log.debug('player profile report was mounted');
  },

  computed: {
    watchedDataForReport() {
      return {
        player: this.player,
        reportKey: this.reportKey,
        dynamicFilters: this.dynamicFilters,
      };
    },
    reportKey() {
      if (this.player) {
        let key = [this.player.Id, this.context, this.reportType];
        if (this.staticFilters.seasonFrom) {
          key.push(this.staticFilters.seasonFrom);
        }
        if (this.staticFilters.seasonTo) {
          key.push(this.staticFilters.seasonTo);
        }
        if (this.staticFilters.gameType) {
          key.push(this.staticFilters.gameType);
        }
        if (this.staticFilters.team) {
          key.push(this.staticFilters.team);
        }
        if (this.staticFilters.selectedDatesRange && this.staticFilters.selectedDatesRange.length) {
          key.push(this.staticFilters.selectedDatesRange.join(''));
        }
        return key.join('-');
      }
      return '';
    },
    enumKey() {
      let enumKey = `${this.context}-${this.reportType}`;
      return enumKey;
    },
    chartSettings() {
      if (ReportsEnum.hasOwnProperty(this.enumKey)) {
        return ReportsEnum[this.enumKey];
      }
      return false;
    },
    ...mapGetters('playerProfiles', ['getReportByKey', 'reportAvailable', 'reportLoading']),

    currentReport() {
      if (this.reportKey) {
        return this.getReportByKey(this.reportKey);
      }
      return undefined;
    },

    mappedComponentName() {
      switch (this.componentName) {
        case 'ProfilePitchingReleasePoint':
        case 'ProfilePitchingReleaseExtension':
        case 'ProfilePitchingPitchMovement':
        case 'ProfilePitchingPitchLocation':
        case 'ProfileBattingStrikeZone':
        case 'ProfileBattingSprayChart':
        case 'ProfilePitchingPerformanceTracking':
        case 'ProfileBattingPerformanceTracking':
        case 'ProfileBattingStrikeZone2':
        case 'ProfilePitchingPitchLocation2':
          return 'HeatmapReport';
        case 'ProfilePitchingVideos':
          return 'ProfileVideos';
        case 'ProfileBattingVideos':
          return 'ProfileVideos';
        default:
          return this.componentName;
      }
    },

    mappedReport() {
      const pitchMapper = new Mappers.TransposedResultsMapper(
        false,
        undefined,
        Enums.ResultType.Pitch.key,
        Enums.ResultData.Data.key,
      );
      const hitMapper = new Mappers.TransposedResultsMapper(
        false,
        undefined,
        Enums.ResultType.Hit.key,
        Enums.ResultData.Data.key,
      );
      const mapper = new Mappers.AggregatedResultsMapper(false, undefined, pitchMapper, hitMapper);

      if (this.showDummyData) {
        const dummyData = this.context === EReportContext.PITCHING.key ?
          unisportDummyReport.pitching
          : unisportDummyReport.batting;

        const mapped = mapper.map(dummyData);

        return playerResultFormatter(mapped);
      }

      if (this.currentReport && this.currentReport.data) {
        const mapped = mapper.map(this.currentReport.data);

        return playerResultFormatter(mapped);
      }
      return undefined;
    },

    chartDataProviderConf() {
      return {
        key: this.enumKey,
        conf: this.chartSettings,
      };
    },

    props() {
      switch (this.componentName) {
        case 'ProfilePitchingPitchLocation2':
        case 'ProfileBattingStrikeZone2':
          return {
            chartComponent: () => import('@/components/ui/charts/StrikeZoneHmap.vue'),
          };
        case 'ProfileBattingVideos':
        case 'ProfilePitchingVideos': {
          return {
            reportKey: this.reportKey,
          };
        }
        case 'ProfilePitchingSummary':
        case 'ProfileBattingSummary':
          return {
            showExport: true,
          };
        default:
          return undefined;
      }
    },
  },

  methods: {
    ...mapActions('playerProfiles', {
      fetchPlayerReportFromApi: PLAYER_REPORT_REQUEST,
    }),

    fetchWithQuery() {
      if (this.query && this.reportKey) {
        this.fetchPlayerReportFromApi({ ...this.query, Key: this.reportKey });
      }
    },

    handler(newVal, oldVal) {
      if (this.player && !this.currentReport && newVal.reportKey) {
        this.fetchWithQuery();
      }
    },
  },

  watch: {
    watchedDataForReport: {
      handler: 'handler',
      immediate: true,
      deep: true,
    },
  },
};
