<template>
  <div class="mb-12">
    <v-card
      v-if="fetching || deleting"
      elevation="0"
      class="d-flex justify-center"
      :loading="fetching || deleting">
      <v-card-text class="text-center py-5">
        <p>{{ $vuetify.lang.t(loaderText) }}</p>
      </v-card-text>
    </v-card>

    <!-- <follow-wizard
      v-if="fetched && !fetching"
      @update-list="updateList"></follow-wizard> -->

    <followed-list
      :followed="followed" v-if="fetched && !fetching && followed.length"
      :disabled="deleting"
      @unfollow-tag="unfollow"></followed-list>

    <p v-else>In order to start tracking a player select his team below and click "track" button.</p>
  </div>
</template>

<script>
import HasPlayers from '@/components/mixins/HasPlayers';
import HasConnectedTags from '@/components/mixins/HasConnectedTags';
import FollowWizard from './FollowWizard.vue';
import FollowedList from './FollowedList.vue';

export default {
  name: 'FollowedTags',

  mixins: [HasPlayers, HasConnectedTags],

  components: {
    FollowWizard,
    FollowedList,
  },

  props: {
    loaderText: {
      type: String,
      default: 'Checking system for data...',
    },
  },

  data() {
    return {
      deleting: false,
      followed: [],
    };
  },

  mounted() {
    this.fetch();
  },

  computed: {
    fetching() {
      return this.$store.state.entities.connectedTags.fetching;
    },
    fetched() {
      return this.$store.state.entities.connectedTags.fetched;
    },
  },

  methods: {
    async fetch() {
      let followed = await this.ConnectedTagRepo.listFollowedPlayers();
      this.followed = followed;
    },

    async updateList() {
      let followed = await this.ConnectedTagRepo.listFollowedPlayers(true);
      this.followed = followed;

      this.$emit('followedListUpdated');
      this.$log.debug(this.followed);
    },

    async unfollow(tag) {
      this.deleting = true;

      let result = await this.ConnectedTagRepo.delete(tag.playerTagID);

      if (result) {
        let followed = await this.ConnectedTagRepo.listFollowedPlayers(true);
        this.followed = followed;
        this.$log.debug(this.followed);
      }

      this.deleting = false;
    },
  },
};
</script>
