<template>
<v-autocomplete
  v-model="selected"
  :menu-props="menuProps"
  :label="label"
  :items="selectOptions"
  :disabled="disabled"
  :item-text="itemKey"
  :item-value="itemValue"
  :multiple="multiple"
  :loading="loading"
  hide-details
  dense
  outlined>
</v-autocomplete>
</template>

<script>
import FilterInStore from '../../mixins/FilterInStore';

export default {
  name: 'StoreSimpleSelect',
  mixins: [FilterInStore],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    possibleOptions: {
      type: Array,
      default: null,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // store: {
    //   type: String,
    //   required: true,
    // },
    // name: {
    //   type: String,
    //   required: true,
    // },
    // label: {
    //   type: String,
    //   required: true,
    // },
    // storeAction: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      disabled: false,
      menuProps: {
        offsetY: true,
        maxHeight: 300,
      },
      // items: [
      //   { text: 'All', value: '' },
      //   { text: 'Windup', value: 'WU' },
      //   { text: 'Stretch ', value: 'ST' },
      //   { text: 'Unidentified', value: 'UI' },
      // ],
    };
  },
  computed: {
    // storePath() {
    //   return `${this.store}.${this.name}`;
    // },

    // selected: {
    //   get() {
    //     return this.$store.get(`${this.store}.${this.name}`) || '';
    //   },
    //   set(value) {
    //     this.disabled = true;
    //     const vm = this;
    //     this.$store.dispatch(this.storeAction, { path: this.name, value }).then(() => {
    //       vm.disabled = false;
    //     });
    //   },
    // },
    allItems() {
      return this.selected.length === this.options.length;
    },
    someItems() {
      return this.selected.length > 0 && !this.allItems;
    },
    selectOptions() {
      const possible = this.possibleOptions;
      if (!(possible instanceof Array) ){
        return this.options;
      }

      return this.options.map( o => {
        o.disabled = !possible.includes(o.value);
        return o;
      });
    },
    icon() {
      if (this.allItems) return 'mdi-close-box';
      if (this.someItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allItems) {
          this.selected = [];
        } else {
          this.selected = this.options.slice();
        }
      });
    },
  },
};
</script>

<style lang="scss" module>

</style>
