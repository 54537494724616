import isUndefined from 'lodash/isUndefined';

export default {
  props: {
    store: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    storeAction: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    storePath() {
      return `${this.store}.${this.name}`;
    },
    selected: {
      get() {
        if (!isUndefined(this.$store.get(this.storePath))) {
          return this.$store.get(this.storePath);
        }
        return (this.multiple ? [] : '');
      },
      set(value) {
        this.disabled = true;
        const vm = this;
        this.$store.dispatch(this.storeAction, { path: this.name, value }).then(() => {
          vm.disabled = false;
        });
        this.$emit('input', value);
      },
    },
    isSetInStore() {
      return !isUndefined(this.$store.get(this.storePath));
    },
  },
};
