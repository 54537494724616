
<script>
import SeasonSelect from './SeasonSelect.mixin';

export default {
  name: 'CoreStupidSeasonSelect',

  mixins: [SeasonSelect],

  props: {
    value: {},
  },

  computed: {
    season: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<template>
<div>
  <v-select
    v-model="season"
    :items="seasonsMapped"
    :menu-props="menuProps"
    item-value="id"
    item-text="displayName"
    return-object
    hide-details
    outlined
    v-bind="$attrs"
  ></v-select>
</div>
</template>
