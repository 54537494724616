import Wrapper from '@/components/ui/Wrapper.vue';
import PlayersProps from '@/modules/player-profiles/mixins/PlayersProps';
import PlayersList from '@/modules/player-profiles/PlayersList.vue';
import PlayersFilter from '@/modules/player-profiles/PlayersFilter.vue';
import FollowedTags from '@/modules/player-profiles/views/FollowedTags.vue';

export default {
  metaInfo: {
    title: 'Player List',
  },
  components: {
    Wrapper,
    PlayersFilter,
    PlayersList,
    FollowedTags,
  },
  mixins: [PlayersProps],

  methods: {
    onUpdateFollowed() {
      this.$refs.followedTags.updateList()
    },

    onUpdateList() {
      this.$refs.playersList.refresh();
    },
  },
};
