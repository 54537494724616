import find from 'lodash/find';
import { mapGetters } from 'vuex';
import { pushRouteContext } from '@/router';
import ResultFilter from '@/filters/ResultFilter';
import { EHandednessExtended, EPitchSet, EPOuts, ERunnersOn, EPitchParameters, EHitParameters } from '@/filters/enums';
import PitchResultDropdown from '@/components/filters/PitchResultDropdown.vue';
import PitchTypeDropdown from '@/components/filters/PitchTypeDropdown.vue';
import ScoringCountsFilter from '@/components/filters/ScoringCountsFilter.vue';
import SimpleSelect from '@/components/ui/selection-controls/SimpleSelect.vue';
import PlayOutcomeDropdown from '@/components/filters/PlayOutcomeDropdown.vue';
import { EReportContext } from '@/enums/reports';

const selectsCollection = [
  {
    name: ResultFilter.PitchParameter.key,
    label: ResultFilter.PitchParameter.name,
    multiple: false,
    component: 'v-select',
    model: ResultFilter.PitchParameter.filterKey,
    outerClassList: ['ml-4', 'pt-1'],
    defaultValue: EPitchParameters[0],
    props: {
      items: EPitchParameters,
      itemText: 'name',
      itemValue: 'key',
      returnObject: true,
    },
  },
  {
    name: ResultFilter.HitParameter.key,
    label: ResultFilter.HitParameter.name,
    multiple: false,
    component: 'v-select',
    model: ResultFilter.HitParameter.filterKey,
    outerClassList: ['ml-4', 'pt-1'],
    defaultValue: EHitParameters[0],
    props: {
      items: EHitParameters,
      itemText: 'name',
      itemValue: 'key',
      returnObject: true,
    },
  },
  {
    name: ResultFilter.PitcherHandedness.key,
    label: ResultFilter.PitcherHandedness.name,
    multiple: false,
    component: 'v-select',
    model: ResultFilter.PitcherHandedness.filterKey,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
    props: {
      items: EHandednessExtended,
    },
  },
  {
    name: ResultFilter.BatterHandedness.key,
    label: ResultFilter.BatterHandedness.name,
    multiple: false,
    component: 'v-select',
    model: ResultFilter.BatterHandedness.filterKey,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
    props: {
      items: EHandednessExtended,
    },
  },
  {
    name: ResultFilter.PitchSet.key,
    label: ResultFilter.PitchSet.name,
    component: 'v-select',
    model: ResultFilter.PitchSet.filterKey,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
    props: {
      items: EPitchSet,
    },
  },
  {
    name: ResultFilter.PlayOutcome.key,
    label: ResultFilter.PlayOutcome.name,
    component: PlayOutcomeDropdown,
    model: ResultFilter.PlayOutcome.filterKey,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
  },
  {
    name: ResultFilter.PitchResult.key,
    label: ResultFilter.PitchResult.name,
    component: PitchResultDropdown,
    model: ResultFilter.PitchResult.filterKey,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
  },
  {
    name: ResultFilter.PitchType.key,
    label: ResultFilter.PitchType.name,
    component: PitchTypeDropdown,
    model: ResultFilter.PitchType.filterKey,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
  },

  {
    name: ResultFilter.ScoringOuts.key,
    label: ResultFilter.ScoringOuts.name,
    model: ResultFilter.ScoringOuts.filterKey,
    component: SimpleSelect,
    multiple: true,
    outerClassList: ['ml-4', 'pt-1', 'short-filter'],
    props: {
      options: EPOuts,
    },
  },
  {
    name: ResultFilter.ScoringCounts.key,
    label: ResultFilter.ScoringCounts.name,
    component: ScoringCountsFilter,
    outerClassList: ['ml-4', 'pt-1', 'wide-filter'],
    multiple: true,
  },
  {
    name: ResultFilter.ScoringRunnersOn.key,
    label: `Bases / ${ResultFilter.ScoringRunnersOn.name}`,
    model: ResultFilter.ScoringRunnersOn.filterKey,
    component: SimpleSelect,
    multiple: false,
    outerClassList: ['ml-4', 'pt-1', 'medium-filter'],
    props: {
      options: ERunnersOn,
    },
  },
  // TODO: handle in future
  // {
  //   name: ResultFilter.Zones.key,
  //   label: ResultFilter.Zones.name,
  //   component: SelectZone,
  //   model: ResultFilter.Zones.filterKey,
  //   outerClassList: ['ml-4', 'pt-1', 'short-filter'],
  // },
];

export default {
  name: 'DynamicPlayerFilters',
  props: {
    context: {
      type: String,
    },
    reportType: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    currentReport: {
      type: Object,
      default: null,
    },
    links: {
      type: Array,
      default: () => [],
    },
    dynamicFilters: {
      type: Object,
      default: () => {},
    },

    contextLabel: {
      type: String,
      default: 'Context',
    },

    contextList: {
      type: Array,
      default: () => [
        { value: EReportContext.BATTING.key, text: EReportContext.BATTING.label },
        { value: EReportContext.PITCHING.key, text: EReportContext.PITCHING.label },
      ],
    },
  },
  data() {
    return {
      menuProps: {
        offsetY: true,
        maxHeight: 'auto'
      },
      baseTeam: { text: this.$vuetify.lang.t('$vuetify.playerFilters.baseTeamText'), value: '' },
      batHandednessLabel: this.$vuetify.lang.t('$vuetify.playerFilters.batHandednessLabel'),
      chartTypeLabel: this.$vuetify.lang.t('$vuetify.playerFilters.chartTypeLabel'),
      throwHandednessLabel: this.$vuetify.lang.t('$vuetify.playerFilters.throwHandednessLabel'),

      localFilters: {},
    };
  },

  computed: {
    ...mapGetters('entities/teams', ['allEventTeamIDs']),
    ...mapGetters(['isProfileLoaded']),
    selectedTeamId() {
      return this.$store.state.playerProfiles.filter.team;
    },
    filtersStore() {
      return 'playerProfiles@filter';
    },
    currentReportIcon() {
      return this.currentReport ? `$vuetify.icons.${this.currentReport.icon}` : '';
    },
    seasons() {
      return [];
    },

    currentLink() {
      if (this.currentReport) {
        return find(this.links, {
          params: { context: this.currentReport?.params.context, reportType: this.currentReport?.params.reportType },
        });
      }
      return null;
    },

    dynamicFiltersList() {
      // If link was not found filtering selectsCollection is unnecessary
      if (!this.currentLink) {
        return [];
      }
      return selectsCollection.filter(
        (select) => this.currentLink.selects && this.currentLink.selects.includes(select.name),
      );
    },

    visibleLinks() {
      return this.links.filter(({ header, params, hide }) => {
        if (header) return false;
        if (params?.context !== this.context) return false;
        return !hide;
      });
    },
  },

  methods: {
    pushRouteContext,

    compareComponents(component) {
      return !(typeof component === 'object' && component.name === 'ScoringCountsFilter');
    },

    updateFilter(key, value) {
      this.$set(this.dynamicFilters, key, value);
    },

    setDefaultFilters(val) {
      if (val) {
        let test = val
          .filter(({ defaultValue, model }) => defaultValue && model)
          .map(({ defaultValue, model }) => {
            this.$set(this.dynamicFilters, model, defaultValue);
          });
      }
    },
  },

  watch: {
    dynamicFiltersList: {
      handler: 'setDefaultFilters',
      immediate: true,
    },
  },
};
