import { Calculators, Enums, Providers, Selectors, Formatters } from '@flightscope/baseball-stats';
import orderBy from 'lodash/orderBy';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import { mapState } from 'vuex';
import { unitsConverter, conversionType } from '@/plugins/converter';
import { UNIT_SYSTEMS, defaultSystem } from '@/store/modules/user';
import { PlayerModel } from '@/models/PlayerModel';
import { EReportContext } from '@/enums/reports';
import { mapEnumsToDatatableHeaderItems } from '@/utils/mappers';
import { League } from '@/models/orm/Hierarchy';
import NoData from '@/components/core/NoData.vue';
import CareerStatsTable from './CareerStatsTable';

const generalHeaders = [Enums.GeneralRankingStats.GamesPlayed];

const allPitchingHeaders = [
  Enums.PitchingRankingStats.Wins,
  Enums.PitchingRankingStats.Looses,
  Enums.PitchingRankingStats.EarnedRunsAverage,
  Enums.PitchingRankingStats.GamesStarted,
  Enums.PitchingRankingStats.GamesCompleted,
  // Enums.PitchingRankingStats.Shutouts,
  // TODO: HLD, SV, SVO
  Enums.PitchingRankingStats.InningsPitched,
  Enums.PitchingRankingStats.HitsAllowed,
  Enums.PitchingRankingStats.RunsAllowed,
  Enums.PitchingRankingStats.EarnedRuns,
  Enums.PitchingRankingStats.HomeRunsAllowed,
  Enums.PitchingRankingStats.NumberOfPitches,
  Enums.PitchingRankingStats.HitBatsmen,
  Enums.PitchingRankingStats.WalksAllowed,
  Enums.PitchingRankingStats.IntentionalWalksAllowed,
  Enums.PitchingRankingStats.Strikeouts,
  Enums.PitchingRankingStats.BattingAverage,
  Enums.PitchingRankingStats.WalksPlusHitsPerInningPitched,
  Enums.PitchingRankingStats.GroundOutToAirOutRatio,
  // Enums.PitchingRankingStats.QualityStarts,
  Enums.PitchingRankingStats.GamesFinished,
  Enums.PitchingRankingStats.TwoBaseHitsAllowed,
  Enums.PitchingRankingStats.ThreeBaseHitsAllowed,
  Enums.PitchingRankingStats.GroundIntoDoublePlays,
  // TODO: add GIDPO
  Enums.PitchingRankingStats.WildPitches,
  Enums.PitchingRankingStats.Balks,
  Enums.PitchingRankingStats.StolenBasesAllowed,
  Enums.PitchingRankingStats.CaughtStealingAllowed,
  Enums.PitchingRankingStats.Pickoffs,
  // TODO: S%, P/IP
  Enums.PitchingRankingStats.PitchesPerPa,
  // TODO: add RS/9
  Enums.PitchingRankingStats.WinningPercentage,
  Enums.PitchingRankingStats.BattersFaced,
  Enums.PitchingRankingStats.BattingAverageOnBallsInPlay,
  Enums.PitchingRankingStats.OnBaseAgainst,
  Enums.PitchingRankingStats.SluggingPercentage,
  Enums.PitchingRankingStats.OnBasePlusSlugging,
  Enums.PitchingRankingStats.StrikeoutsPerNineInnings,
  Enums.PitchingRankingStats.WalksPerNineInnings,
  Enums.PitchingRankingStats.HomeRunsPerNineInnings,
  Enums.PitchingRankingStats.HitsPerNineInnings,
  Enums.PitchingRankingStats.StrikeoutToWalkRatio,
  // TODO: IR, IR_S, BQR, BQR_S
];

const allBattingHeaders = [
  Enums.BattingRankingStats.AtBats,
  Enums.BattingRankingStats.Runs,
  Enums.BattingRankingStats.Hits,
  Enums.BattingRankingStats.TwoBaseHits,
  Enums.BattingRankingStats.ThreeBaseHits,
  Enums.BattingRankingStats.HomeRuns,
  Enums.BattingRankingStats.RunsBattedIn,
  Enums.BattingRankingStats.Walks,
  Enums.BattingRankingStats.IntentionalWalks,
  Enums.BattingRankingStats.Strikeouts,
  Enums.BattingRankingStats.StolenBases,
  Enums.BattingRankingStats.CaughtStealing,
  Enums.BattingRankingStats.BattingAverage,
  Enums.BattingRankingStats.OnBasePercentage,
  Enums.BattingRankingStats.SluggingPercentage,
  Enums.BattingRankingStats.OnBasePlusSlugging,
  Enums.BattingRankingStats.GroundOutToAirOutRatio,
  Enums.BattingRankingStats.PlateAppearances,
  Enums.BattingRankingStats.TotalBases,
  Enums.BattingRankingStats.ExtraBaseHits,
  Enums.BattingRankingStats.HitByPitches,
  Enums.BattingRankingStats.SacrificeHits,
  Enums.BattingRankingStats.SacrificeFlies,
  Enums.BattingRankingStats.BattingAverageOnBallsInPlay,
  Enums.BattingRankingStats.GroundIntoDoublePlays,
  // TODO: GIDPO
  Enums.BattingRankingStats.NumberOfPitches,
  Enums.BattingRankingStats.PitchesPerPa,
  Enums.BattingRankingStats.ReachedOnError,
  // Enums.BattingRankingStats.LeftOnBase,
  // TODO: WO
];

// based on MLB's data, @see https://www.mlb.com/player/kevin-gausman-592332
const basicPitchingHeaders = [
  Enums.PitchingRankingStats.Wins,
  Enums.PitchingRankingStats.Looses,
  Enums.PitchingRankingStats.EarnedRunsAverage,
  ...generalHeaders,
  Enums.PitchingRankingStats.GamesStarted,
  Enums.PitchingRankingStats.GamesCompleted,
  // Enums.PitchingRankingStats.Shutouts,
  // TODO: HLD, SV, SVO
  Enums.PitchingRankingStats.InningsPitched,
  Enums.PitchingRankingStats.HitsAllowed,
  Enums.PitchingRankingStats.RunsAllowed,
  Enums.PitchingRankingStats.EarnedRuns,
  Enums.PitchingRankingStats.HomeRunsAllowed,
  Enums.PitchingRankingStats.NumberOfPitches,
  Enums.PitchingRankingStats.HitBatsmen,
  Enums.PitchingRankingStats.WalksAllowed,
  Enums.PitchingRankingStats.IntentionalWalksAllowed,
  Enums.PitchingRankingStats.Strikeouts,
  Enums.PitchingRankingStats.BattingAverage,
  Enums.PitchingRankingStats.WalksPlusHitsPerInningPitched,
  Enums.PitchingRankingStats.GroundOutToAirOutRatio,
];

const advancedPitchingHeaders = [
  // Enums.PitchingRankingStats.QualityStarts,
  Enums.PitchingRankingStats.GamesFinished,
  Enums.PitchingRankingStats.TwoBaseHitsAllowed,
  Enums.PitchingRankingStats.ThreeBaseHitsAllowed,
  Enums.PitchingRankingStats.GroundIntoDoublePlays,
  // TODO: add GIDPO
  Enums.PitchingRankingStats.WildPitches,
  Enums.PitchingRankingStats.Balks,
  Enums.PitchingRankingStats.StolenBasesAllowed,
  Enums.PitchingRankingStats.CaughtStealingAllowed,
  Enums.PitchingRankingStats.Pickoffs,
  // TODO: S%, P/IP
  Enums.PitchingRankingStats.PitchesPerPa,
  // TODO: add RS/9
  Enums.PitchingRankingStats.WinningPercentage,
  Enums.PitchingRankingStats.BattersFaced,
  Enums.PitchingRankingStats.BattingAverageOnBallsInPlay,
  Enums.PitchingRankingStats.OnBaseAgainst,
  Enums.PitchingRankingStats.SluggingPercentage,
  Enums.PitchingRankingStats.OnBasePlusSlugging,
  Enums.PitchingRankingStats.StrikeoutsPerNineInnings,
  Enums.PitchingRankingStats.WalksPerNineInnings,
  Enums.PitchingRankingStats.HomeRunsPerNineInnings,
  Enums.PitchingRankingStats.HitsPerNineInnings,
  Enums.PitchingRankingStats.StrikeoutToWalkRatio,
  // TODO: IR, IR_S, BQR, BQR_S
];

const basicBattingHeaders = [
  ...generalHeaders,
  Enums.BattingRankingStats.AtBats,
  Enums.BattingRankingStats.Runs,
  Enums.BattingRankingStats.Hits,
  Enums.BattingRankingStats.TwoBaseHits,
  Enums.BattingRankingStats.ThreeBaseHits,
  Enums.BattingRankingStats.HomeRuns,
  Enums.BattingRankingStats.RunsBattedIn,
  Enums.BattingRankingStats.Walks,
  Enums.BattingRankingStats.IntentionalWalks,
  Enums.BattingRankingStats.Strikeouts,
  Enums.BattingRankingStats.StolenBases,
  Enums.BattingRankingStats.CaughtStealing,
  Enums.BattingRankingStats.BattingAverage,
  Enums.BattingRankingStats.OnBasePercentage,
  Enums.BattingRankingStats.SluggingPercentage,
  Enums.BattingRankingStats.OnBasePlusSlugging,
  Enums.BattingRankingStats.GroundOutToAirOutRatio,
];

const advancedBattingHeaders = [
  Enums.BattingRankingStats.PlateAppearances,
  Enums.BattingRankingStats.TotalBases,
  Enums.BattingRankingStats.ExtraBaseHits,
  Enums.BattingRankingStats.HitByPitches,
  Enums.BattingRankingStats.SacrificeHits,
  Enums.BattingRankingStats.SacrificeFlies,
  Enums.BattingRankingStats.BattingAverageOnBallsInPlay,
  Enums.BattingRankingStats.GroundIntoDoublePlays,
  // TODO: GIDPO
  Enums.BattingRankingStats.NumberOfPitches,
  Enums.BattingRankingStats.PitchesPerPa,
  Enums.BattingRankingStats.ReachedOnError,
  // Enums.BattingRankingStats.LeftOnBase,
  // TODO: WO
];

export default {
  name: 'CareerStats',

  props: {
    player: {
      type: PlayerModel,
    },
    context: {
      type: String,
    },
    stats: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    NoData,
    CareerStatsTable,
  },

  data() {
    return {
      EReportContext,
      contextIndex: this.context === Enums.RankingScopeContext.Pitching.key ? 1 : 0,
      calculatedStats: {
        [Enums.RankingScopeContext.Pitching.key]: [],
        [Enums.RankingScopeContext.Batting.key]: [],
        [Enums.RankingScopeContext.General.key]: [],
      },
    };
  },

  computed: {
    tabs() {
      return [
        {
          label: Enums.RankingScopeContext.Batting.name,
          headers: [
            { text: 'Season', title: 'Season', value: 'season' },
            ...mapEnumsToDatatableHeaderItems(basicBattingHeaders),
          ],
          advancedHeaders: [
            { text: 'Season', title: 'Season', value: 'season' },
            ...mapEnumsToDatatableHeaderItems(advancedBattingHeaders),
          ],
        },
        {
          label: Enums.RankingScopeContext.Pitching.name,
          headers: [
            { text: 'Season', title: 'Season', value: 'season' },
            ...mapEnumsToDatatableHeaderItems(basicPitchingHeaders),
          ],
          advancedHeaders: [
            { text: 'Season', title: 'Season', value: 'season' },
            ...mapEnumsToDatatableHeaderItems(advancedPitchingHeaders),
          ],
        },
      ];
    },

    leagues() {
      const localLeague = League;
      const sport = process.env.VUE_APP_SPORT_TYPE;

      const leagues = localLeague
        .query()
        .has('seasons', 1)
        .where('sport', (value) => value.toLowerCase().search(sport.toLowerCase()) !== -1)
        .with('seasons')
        .get();

      return leagues;
    },

    seasonsMapped() {
      return this.leagues.reduce((acc, currLeague, i) => {
        const leagueSeasons = orderBy(currLeague.seasons, ['startDate'], ['desc']).map((season) => ({
          id: season.id,
          league: currLeague.displayName,
          displayName: season.displayName,
        }));

        if (Array.isArray(acc)) {
          acc = [...acc, ...leagueSeasons];
        }
        return acc;
      }, []);
    },

    currentContext() {
      return this.contextIndex === 0 ? Enums.RankingScopeContext.Batting.key : Enums.RankingScopeContext.Pitching.key;
    },

    currentStats() {
      if (!this.calculatedStats[this.currentContext].length) {
        return [];
      }

      return merge(
        this.calculatedStats[this.currentContext],
        this.calculatedStats[Enums.RankingScopeContext.General.key],
      );
    },

    hasManyLeagues() {
      const seasonIds = this.stats[this.currentContext].map((stat) => stat.scope.seasonId);
      return uniq(seasonIds).length > 1;
    },

    ...mapState({
      selectedUnitsSystem: (state) => state.user.selectedUnitsSystem || UNIT_SYSTEMS[defaultSystem],
    }),
  },

  methods: {
    addDerivatives(stats, context, headers) {
      const selector = new Selectors.DerivativeRankingStatCalculatorSelector();
      const provider = new Providers.DerivativeRankingStatsProvider(selector);

      if (context === Enums.RankingScopeContext.Pitching.key) {
        selector.register(new Calculators.DerivativeRankingStatCalculators.InningsPitchedRankingStatCalculator(
          new Formatters.InningsPitchedFormatter()
        ));
        selector.register(new Calculators.DerivativeRankingStatCalculators.StrikeoutMinusWalkPercentageStatCalculator(),);
        selector.register(Calculators.DerivativeRankingStatCalculators.PitchingRatioRankingStatCalculator);
        selector.register(new Calculators.DerivativeRankingStatCalculators.OnBasePlusSluggingStatCalculator(context));
        selector.register(new Calculators.DerivativeRankingStatCalculators.EarnedRunsAverageRankingStatCalculator());
      }

      if (context === Enums.RankingScopeContext.Batting.key) {
        selector.register(new Calculators.DerivativeRankingStatCalculators.ExtraBaseHitRankingStatCalculator());
        selector.register(Calculators.DerivativeRankingStatCalculators.BattingRatioRankingStatCalculator);
        selector.register(new Calculators.DerivativeRankingStatCalculators.OnBasePlusSluggingStatCalculator(context));
      }

      return stats.map((stat) => {
        let calculated = provider.calculate(stat.parameters, headers, context, true, false);

        calculated = calculated.reduce((acc, curr, i) => {
          let enumDef;

          switch (context) {
            case Enums.RankingScopeContext.Pitching.key:
              enumDef = Enums.PitchingRankingStats;
              break;

            case Enums.RankingScopeContext.Batting.key:
              enumDef = Enums.BattingRankingStats;
              break;

            case Enums.RankingScopeContext.General.key:
              enumDef = Enums.GeneralRankingStats;
              break;

            default:
              enumDef = {};
              break;
          }

          let { precision, type } = enumDef[curr.parameter];
          precision = precision || 0;
          type = type || conversionType.NUMBER;

          const value =
            curr.value !== null
              ? this.convertValue(curr.value, type, this.selectedUnitsSystem.system).format(undefined, precision)
              : '-';

          acc[curr.parameter] = { value };

          return acc;
        }, {});

        calculated.season = this.seasonsMapped.find((season) => season.id == stat.scope.seasonId);

        return calculated;
      });
    },

    convertValue(value, type, targetSystem) {
      return unitsConverter.convertType(value, type, targetSystem);
    },

    calculateStats(oldValue, newValue) {
      this.calculatedStats[Enums.RankingScopeContext.Pitching.key] = this.addDerivatives(
        this.stats[Enums.RankingScopeContext.Pitching.key],
        Enums.RankingScopeContext.Pitching.key,
        allPitchingHeaders,
      );

      this.calculatedStats[Enums.RankingScopeContext.Batting.key] = this.addDerivatives(
        this.stats[Enums.RankingScopeContext.Batting.key],
        Enums.RankingScopeContext.Batting.key,
        allBattingHeaders,
      );

      this.calculatedStats[Enums.RankingScopeContext.General.key] = this.addDerivatives(
        this.stats[Enums.RankingScopeContext.General.key],
        Enums.RankingScopeContext.General.key,
        generalHeaders,
      );
    },
  },

  watch: {
    stats: {
      handler: 'calculateStats',
    },
  },
};
