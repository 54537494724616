<template>
  <v-row class="selected-chips align-center py-1" no-gutters v-resize="emitHeight">
    <span v-if="filters.batHand" class="label">{{ $vuetify.lang.t('Batter Side') }}</span>

    <v-chip v-if="filters.batHand" class="ma-1" small close @click:close="filters.batHand = ''">{{
      filters.batHand | enumerate(enums.Handedness, 'name', enums.Handedness.Unidentified.key)
    }}</v-chip>

    <span v-if="filters.pitchHand" class="label">{{ $vuetify.lang.t('P.Handedness') }}</span>

    <v-chip v-if="filters.pitchHand" class="ma-1" small close @click:close="filters.pitchHand = ''">{{
      filters.pitchHand | enumerate(enums.Handedness, 'name', enums.Handedness.Unidentified.key)
    }}</v-chip>

    <span v-if="filters.pitchResult.length" class="label">{{ $vuetify.lang.t('Pitch Call') }}</span>

    <v-chip
      v-for="(res, i) in filters.pitchResult"
      :key="`pr-${i}`"
      class="ma-1"
      small
      close
      @click:close="removeValue(res, 'pitchResult')"
      >{{ res | enumerate(enums.PitchResult, 'name', enums.PitchResult.Unidentified.key) }}</v-chip
    >

    <span v-if="filters.pitchType.length" class="label">{{ $vuetify.lang.t('PitchType') }}</span>

    <v-chip
      class="ma-1"
      v-for="(res, j) in filters.pitchType"
      :key="`pt-${j}`"
      small
      close
      @click:close="removeValue(res, 'pitchType')"
      >{{ res | enumerate(enums.PitchType, 'name', enums.PitchType.Unidentified.key) }}</v-chip
    >

    <span v-if="filters.zone.length" class="label">{{ $vuetify.lang.t('Zone') }}</span>

    <v-chip
      class="ma-1"
      v-for="(res, k) in filters.zone"
      :key="`z-${k}`"
      small
      close
      @click:close="removeValue(res, 'zone')"
      >{{ res }}</v-chip
    >

    <span v-if="filters.selectedDatesRange.length" class="label">{{
      $vuetify.lang.t('Date Range')
    }}</span>

    <v-chip v-if="filters.selectedDatesRange.length" class="ma-1" small close @click:close="clearDates">{{
      filters.selectedDatesRange.join(' ~ ')
    }}</v-chip>
  </v-row>
</template>

<script>
import { Enums } from '@flightscope/baseball-stats';

export default {
  name: 'SelectedChips',

  props: {
    value: {
      type: Object,
    },
    height: {
      type: Number,
    }
  },

  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    enums: () => Enums,
  },

  methods: {
    removeValue(val, target) {
      this.$emit('change:filters', val, target);
    },

    clearDates() {
      do {
        this.filters.selectedDatesRange.pop();
      } while (this.filters.selectedDatesRange.length);
    },

    emitHeight() {
      if (this.$el) {
        this.$nextTick(() => {
          this.$emit('update:height', this.$el.offsetHeight);
        });
      }
    },
  },


  watch: {
    filters: {
      handler: 'emitHeight',
      deep: true,
      immediate: true,
    }
  },
};
</script>


<style lang="scss" scoped>
.selected-chips {
  line-height: 30px;
  display: flex;
}

.label {
  font-weight: bold;
  font-size: 10px;
  color: var(--v-mine-shaft-base);
  text-decoration: none solid rgb(0, 0, 0);
  margin-left: 1em;
  margin-right: 1em;
  text-transform: uppercase;
}

::v-deep .v-chip .v-chip__close.v-icon.v-icon--right {
  margin-right: -8px;
}
</style>
