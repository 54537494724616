import { mapGetters } from 'vuex';
import vuetify from '@/plugins/vuetify';
import { concatAndDeDuplicate } from '@/utils/helpers';
import SeasonRangeFilter from './SeasonRangeFilter.vue';
import CustomDateRangeFilter from './CustomDateRangeFilter.vue';
import { EReportContext } from '@/enums/reports';

export default {
  name: 'StaticPlayerFilters',
  props: {
    player: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    staticFilters: {
      type: Object,
      default: () => {},
    },

    context: {
      type: String,
      default: '',
    },

    dateRange: {
      type: Object,
      default: () => ({
        min: null,
        max: null,
      }),
    },
  },
  components: {
    CustomDateRangeFilter,
    SeasonRangeFilter,
  },
  data() {
    return {
      menuProps: {
        offsetY: true,
        maxHeight: 'auto',
      },
      baseTeam: { text: vuetify.framework.lang.t('$vuetify.playerFilters.baseTeamText'), value: '' },
      teamLabel: vuetify.framework.lang.t('$vuetify.playerFilters.teamLabel'),
      gameTypeLabel: vuetify.framework.lang.t('$vuetify.playerFilters.gameTypeLabel'),
      gameTypeSelectOptions: [
        { text: vuetify.framework.lang.t('$vuetify.playerFilters.gameTypeAll'), value: null },
        { text: vuetify.framework.lang.t('$vuetify.playerFilters.gameTypeGame'), value: ['match', null] },
        { text: vuetify.framework.lang.t('$vuetify.playerFilters.gameTypeGameVerified'), value: ['match', true] },
        { text: vuetify.framework.lang.t('$vuetify.playerFilters.gameTypePractice'), value: ['practice', false] },
      ],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters('entities/teams', ['allEventTeamIDs']),
    ...mapGetters(['isProfileLoaded']),
    selectedTeamId() {
      return this.$store.state.playerProfiles.filter.team;
    },
    teamsSelectOptions() {
      // const { seasonFrom, seasonTo } = this.staticFilters;
      // TODO:consider filtering via season
      const teams = this.player.teams
        .map((team) => ({
          text: team.TeamDisplayName,
          value: team.TeamID,
        }));

      return concatAndDeDuplicate(this.baseTeam, teams);
    },

    eventDates() {
      if (this.player) {
        if (this.context === EReportContext.BATTING.key) {
          return this.player.nonPitchingPracticeDates;
        }
        if (this.context === EReportContext.PITCHING.key) {
          return this.player.nonBattingPracticeDates;
        }
      }
      return [];
    },
  },
};
