<template>
  <v-col cols="12" class="px-0" v-if="visible">
    <v-container class="ml-0 pt-0 pl-0 root-container" fluid>
      <v-row>
        <v-col cols="12" lg="6" v-for="({ lines, ctaText, ctaAction, image }, i) in planUpgrades" :key="`block-${i}`">
          <v-card class="fill-height overflow-hidden">
            <v-container fluid>
              <v-row>
                <v-col class="pr-5 pl-12 pt-9 pb-7">
                  <template v-for="({ classList, text, textPrefix, textSuffix, component, props }, i) in lines">
                    <span :key="`prefix-${i}`" v-if="textPrefix" v-html="textPrefix"></span>
                    <component :key="i" :is="component" :class="classList" v-bind="props">
                      <span v-if="text" v-html="text"></span>
                      <component v-else-if="component" :is="component"></component>
                    </component>
                    <span :key="`suffix-${i}`" v-if="textSuffix" v-html="textSuffix"></span>
                  </template>
                  <div class="clearfix"></div>
                  <v-btn small color="primary" @click="ctaAction" class="mt-9 py-4">{{ ctaText }}</v-btn>
                </v-col>
                <v-col  class="pa-0">
                  <v-img max-height="400" min-height="312" :src="image" ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { AclEntities, AclPermissions } from '../../../services/ability';
import { levels, servicePlans } from '../../service-plan/tools/enums';
const TextWithTooltip = () => import('../../../components/ui/tooltips/TextWithTooltip.vue');

export default {
  name: 'Honeytrap',

  computed: {
    visible() {
      return this.$can(AclPermissions.read, AclEntities.Subscription);
    },

    advImgUrl() {
      return process.env.VUE_APP_ADV_LEVEL0 || '/img/banner/honeytrap.jpg';
    },

    planUpgrades() {
      return [
        {
          lines: [
            {
              component: 'p',
              classList: 'line1 font-weight-bold mb-0 black--text',
              text: `Upgrade your service plan to ${servicePlans[levels.LEVEL1].title}`,
            },
            {
              component: 'h3',
              classList: 'font-nunito font-weight-bold line2 mb-7 black--text',
              text: servicePlans[levels.LEVEL1].levelText,
            },
            {
              component: 'p',
              classList: 'line-3 mb-0 black--text',
              text: `and get access to pitching and batting statistics,
                in-detail pitching and batting analysis and historical performance statistics.`,
            },
          ],
          ctaText: `Upgrade plan to ${servicePlans[levels.LEVEL1].title}`,
          ctaAction: this.redirectToManageMembership,
          image: '/img/banner/level_1_add 1.png',
        },
        {
          lines: [
            {
              component: 'p',
              classList: 'line1 font-weight-bold mb-0 black--text',
              text: `Upgrade your service plan to ${servicePlans[levels.LEVEL2].title}`,
            },
            {
              component: 'h3',
              classList: 'font-nunito font-weight-bold line2 mb-7 black--text',
              text: servicePlans[levels.LEVEL2].levelText },
            {
              component: 'p',
              classList: 'line-3 mb-0 black--text',
              text: 'and get access to batting and pitching video clips, storage for up to 1000 video clips, Twitter sharing function as well as track performance of the players of your choice.',
            },
          ],
          ctaText: `Upgrade plan to ${servicePlans[levels.LEVEL2].title}`,
          ctaAction: this.redirectToManageMembership,
          image: '/img/banner/MacBook-Mockup2 1.png',
        },
      ];
    },
  },
  methods: {
    redirectToManageMembership() {
      this.$router.push({
        name: 'profile.manageMembership',
      });
    },
  },
};
</script>

<style scoped>
.root-container {
  --header-height: 60px;
  --paddings: 24px;
  max-height: calc(100vh - var(--header-height) - var(--paddings));
  overflow: auto;
}
.line2 {
  font-size: 2.5rem;
}
.line3 {
  line-height: 21/14;
  font-size: 0.875rem;
}
</style>
