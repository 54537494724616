import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import has from 'lodash/has';
import merge from 'lodash/merge';
import sanitize from 'sanitize-filename';
import {
  Providers,
  Entities,
  DataBinning,
  Mappers,
  Enums,
  ValueObjects,
  Converters
} from '@flightscope/baseball-stats';

import { GenericStrikeZone } from '@/enums/Generic';
import { convertValue, measurementSystem } from '@/plugins/converter';
import { unitSymbolProvider } from '@/filters/units';
import { Layouts, Configs, Images, Traces } from '@/components/ui/charts/plotly/PlotlyHelpers';
import binningRangeSettingsProvider from '@/components/ui/charts/BinningRangeSettingsProvider';
import mapDefaultTraceSettings from '@/components/ui/charts/mappers/mapDefaultTraceSettings';
import determineChartAxisRange from '@/providers/ChartAxisRangeProvider';
import { ChartTypes } from './configs/ChartTypes';
import checker from './helpers/checker';
import customizeTraceByContext from './mappers/customizeTraceByContext';
import calculateSeriesStats from './helpers/calculateSeriesStats';

/**
 *
 * @param {Object} range
 * @param {Number} range.min
 * @param {Number} range.max
 */
function setRange({ min, max }) {
  return [min, max];
}

function contextFilterGuard(chartContext, filters = {}) {
  switch (chartContext) {
    case 'batting-strike-zone':
    case 'batting-spray-chart':
    case 'batting-strike-zone-2':
      return checker(filters, 'batterId');

    case 'batting-batters-performance':
    case 'batting-plate-discipline':
      return checker(filters, ['batterIds', 'pitchType']);

    case 'pitching-release-point':
    case 'pitching-release-extension':
    case 'pitching-pitch-movement':
    case 'pitching-pitch-location':
    case 'pitching-pitch-location-2':
      return checker(filters, 'pitcherId');

    case 'pitching-pitchers-performance':
    case 'pitching-pitchers-repeatability':
      return checker(filters, ['pitcherIds', 'pitchType']);
    case 'pitching-plate-discipline':
      return checker(filters, ['pitcherIds', 'pitchType']);

    case 'pitching-performance-tracking':
      return checker(filters, ['pitcherId', 'pitchParameter']);
    case 'batting-performance-tracking':
      return checker(filters, ['batterId', 'hitParameter']);

    default:
      throw new TypeError(`${chartContext} is not implemented !!!`);
  }
}

function convertSeriesToSequential(oneOfSeries, multiplier, dimension) {
  const centering = multiplier > 1 ? (multiplier - 1) / 2 : 0;
  for (let m = 0; m < oneOfSeries.length;) {
    oneOfSeries[m][dimension - 1] = (++m * multiplier) - centering;
  }
}

function initialSeriesProvider(chartContext, data = [], filters = {}, { providerConfig, providerAdditionalParams }) {
  let provider;
  switch (chartContext) {
    case 'batting-spray-chart':
      return Providers.UnifiedSprayChartSeriesProvider(data, undefined, filters);

    case 'batting-strike-zone':
      return Providers.StrikeZoneChartSeriesProvider(data, undefined, filters);

    case 'pitching-release-point':
      return Providers.ReleaseChartsSeriesProvider(data, undefined, filters);

    case 'pitching-release-extension':
      return Providers.ExtensionChartsSeriesProvider(data, undefined, filters);

    case 'pitching-pitch-location':
      return Providers.LocationChartSeriesProvider(data, undefined, filters);

    case 'pitching-pitch-movement':
      return Providers.MovementChartSeriesProvider(data, undefined, filters);

    case 'pitching-pitchers-performance':
      provider = new Providers.PitchersPerformanceSeriesProvider(...providerConfig);
      return provider.getData(
        data,
        filters,
        process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key ? [] : providerAdditionalParams,
      );

    case 'pitching-pitchers-repeatability':
      provider = new Providers.PitchersRepeatabilitySeriesProvider(...providerConfig);
      return provider.getData(data, filters);

    case 'batting-batters-performance': {
      let [maxMlbValues, baseOnParams] = providerConfig;
      provider = new Providers.BattersPerformanceSeriesProvider(maxMlbValues, baseOnParams);
      return provider.getData(
        data,
        filters,
        process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key ? [] : [maxMlbValues],
      );
    }

    case 'batting-plate-discipline': {
      provider = new Providers.BattersPlateDisciplineSeriesProvider(providerConfig);
      return provider.getData(
        data,
        filters,
        process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Softball.key
          ? []
          : [ValueObjects.ComparisonData.MLB.MLB2019.BattingStats],
      );
    }

    case 'pitching-performance-tracking': {
      const { pitchParameter } = filters;
      let series = Providers.PitchTrackingChartSeriesProvider(data, undefined, pitchParameter.key, filters);

      // lets convert Pitch Count on X axis to sequential number
      for (let s = 0; s < series.length; s++) {
        convertSeriesToSequential(series[s], 1, Enums.SeriesDimension.X);
      }

      const movingAvgCount = 3;
      const movingAvg = new Converters.SeriesConverters.MovingAverageSeriesConverter(movingAvgCount, [2], true);
      const seriesAvg = movingAvg.convertSingle(series[0]);
      convertSeriesToSequential(seriesAvg, movingAvgCount, Enums.SeriesDimension.X);

      series.push(seriesAvg);

      return series;
    }

    case 'batting-performance-tracking': {
      const { hitParameter } = filters;
      let series = Providers.HitTrackingChartSeriesProvider(data, undefined, hitParameter.key, filters);

      // lets convert Plate Appearance on X axis to sequential number
      for (let s = 0; s < series.length; s++) {
        convertSeriesToSequential(series[s], 1, Enums.SeriesDimension.X);
      }

      const movingAvgCount = 3;
      const movingAvg = new Converters.SeriesConverters.MovingAverageSeriesConverter(movingAvgCount, [2], true);
      const seriesAvg = movingAvg.convertSingle(series[0]);
      convertSeriesToSequential(seriesAvg, movingAvgCount, Enums.SeriesDimension.X);

      series.push(seriesAvg);

      return series;
    }

    case 'pitching-plate-discipline': {
      provider = new Providers.PitchersPlateDisciplineSeriesProvider(providerConfig);
      return provider.getData(data, filters, process.env.VUE_APP_SPORT_TYPE === 'softball' ? [] : [ValueObjects.ComparisonData.MLB.MLB2019.PitchingStats]);
    }

    default:
      throw new TypeError(`${chartContext} Not implemented !!!`);
  }
}

/**
 *
 * @param {String} chartContext
 * @param {Array} data
 * @param {Object} config
 * @param {Object} config.axisSettings
 * @param {Object} config.axisRanges
 * @param {Array} config.convertDimensions
 * @param {Object} config.conf
 * @param {Array} config.axisUnit
 * @param {Object} unitSystemConfig
 * @param {String} unitSystemConfig.unitSystem
 */
function contextPropsProvider(
  chartContext,
  data, {
    axisSettings = {},
    axisRanges = {},
    convertDimensions,
    conf,
    axisUnit = []
  },
  { unitSystem }
) {

  const { xaxis, yaxis } = axisSettings;
  const { xRange, yRange } = axisRanges;

  const baseLayout = cloneDeep(Layouts.base);
  let baseConfig = cloneDeep(Configs.base);

  let images = [];

  let cssClass = [];

  let dataArr = data;
  let layout = cloneDeep(Layouts.scatterChart);

  switch (chartContext) {
    case 'batting-strike-zone':
      layout = cloneDeep(Layouts.heatmapChart);
      break;

    case 'batting-spray-chart': {
      const image = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key
        ? Images.baseballCourtBG
        : Images.softballCourtBg;

      layout = cloneDeep(Layouts.sprayChart);

      let convertedCourt = {
        ...{},
        ...image,
        x: convertValue(image.x, convertDimensions[0].conversionType, unitSystem).value,
        y: convertValue(image.y, convertDimensions[0].conversionType, unitSystem).value,
        sizex: convertValue(image.sizex, convertDimensions[0].conversionType, unitSystem).value,
        sizey: convertValue(image.sizey, convertDimensions[1].conversionType, unitSystem).value,
      };
      images.push(convertedCourt);

      cssClass.push('max-chart-width');
      break;
    }

    case 'pitching-release-point': {
      const image = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key ?
        Images.releasePointPlayer
        : Images.releasePointPlayerSoftball;

      layout = cloneDeep(Layouts.heatmapChart);
      let convertedReleasePointPlayer = {
        ...{},
        ...image,
        x: convertValue(image.x, convertDimensions[0].conversionType, unitSystem).value,
        sizex: convertValue(image.sizex, convertDimensions[0].conversionType, unitSystem).value,
        sizey: convertValue(image.sizey, convertDimensions[1].conversionType, unitSystem).value,
        sizing: 'fill',
      };
      images.push(convertedReleasePointPlayer);

      cssClass.push('max-chart-width');
      break;
    }

    case 'pitching-release-extension': {
      const image = process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key ?
        Images.releaseExtensionPlayer
        : Images.releaseExtensionPlayerSoftball;

      layout = cloneDeep(Layouts.heatmapChart);
      let convertedReleaseExtensionPlayer = {
        ...image,
        sizex: convertValue(image.sizex, convertDimensions[0].conversionType, unitSystem).value,
        sizey: convertValue(image.sizey, convertDimensions[1].conversionType, unitSystem).value,
        sizing: 'fill',
      };
      images.push(convertedReleaseExtensionPlayer);

      cssClass.push('max-chart-width');
      break;
    }

    case 'pitching-pitch-location':
    case 'pitching-pitch-movement':
      layout = cloneDeep(Layouts.heatmapChart);

      cssClass.push('max-chart-width');
      break;

    case 'pitching-pitchers-performance':
    case 'pitching-pitchers-repeatability':
    case 'batting-batters-performance':
    case 'batting-plate-discipline':
    case 'pitching-plate-discipline':
      layout = cloneDeep(Layouts.barchart);

      baseConfig = { ...baseConfig, ...Configs.bar };
      break;

    case 'batting-performance-tracking':
    case 'pitching-performance-tracking': {
      let { enumObj } = convertDimensions[1];
      let { x } = data[0];
      layout = Layouts.performanceTrackingLayoutFn(x, enumObj);
      break;
    }

    default:
      throw new TypeError(`${chartContext} Not implemented !!!`);
  }

  // set config
  // set layout
  if (xRange) {
    layout.xaxis.range = setRange(xRange);
  }
  if (yRange) {
    layout.yaxis.range = setRange(yRange);
  }
  if (xaxis) {
    layout.xaxis.dtick = xaxis.gridSize;
  }
  if (yaxis) {
    layout.yaxis.dtick = yaxis.gridSize;
  }
  if (images.length) {
    layout.images = images;
  }
  const fileDate = new Date().toISOString();
  let { title, playerName, axis } = conf;
  if (title) {
    layout.title = title;
  }

  if (axis) {
    let xAxis = axis.xaxis;
    let yAxis = axis.yaxis;

    if (xAxis) {
      if (axisUnit.length && xAxis.title && axisUnit[0]) {
        if (axisUnit[0]) {
          layout.xaxis.title = `${xAxis.title}, ${axisUnit[0]}`;
          layout.xaxis.titleWithoutUnit = xAxis.title;
        } else {
          layout.xaxis.title = xAxis.title;
        }
      }
      layout.xaxis = merge({}, {...xAxis}, layout.xaxis );
    }

    if (yAxis) {
      if (axisUnit.length > 1 && yAxis.title) {
        layout.yaxis.titleWithoutUnit = yAxis.title;
        layout.yaxis.title = `${yAxis.title}, ${axisUnit[1]}`;
      }
      layout.yaxis = merge({}, {...yAxis}, layout.yaxis );
    } else if (has(layout.yaxis, 'title.text') && axisUnit.length > 1) {
      layout.yaxis.title.textWithoutUnit = layout.yaxis.title.text;
      layout.yaxis.title.text = `${layout.yaxis.title.text}, ${axisUnit[1]}`;
    }

  }
  // set data
  if (typeof dataArr === 'object' && !(data instanceof Array)) {
    dataArr = [data];
  }

  let filename = [fileDate, title, playerName].filter((item) => !!item).join('-');

  baseConfig.toImageButtonOptions.filename = sanitize(filename);

  const baseProps = { layout: baseLayout, config: baseConfig, data: [], class: [] };

  return merge(baseProps, { data: dataArr, layout, class: cssClass });
}

function defaultAxisSettingsProvider(context, isMetric, strikeZoneModel) {
  return binningRangeSettingsProvider(isMetric, context.axisContext, strikeZoneModel);
}

function defaultAxisRangesProvider(context, axisSettings, stats) {
  const { xaxis, yaxis } = axisSettings;

  let { isSymmetric, minRange, padding, gridSize, min, extend } = xaxis;
  let values = stats && stats[1] && extend ? stats[1] : [];
  let xRange = determineChartAxisRange(values, isSymmetric || false, minRange, padding, gridSize, min);

  ({ isSymmetric, minRange, padding, gridSize, min, extend } = yaxis);
  values = stats && stats[2] && extend ? stats[2] : [];
  let yRange = determineChartAxisRange(values, isSymmetric || false, minRange, padding, gridSize, min);

  return { xRange, yRange };
}

function defaultBinningProvider(context, axisSettings, axisRanges) {
  let { xaxis, yaxis } = axisSettings;
  let { xRange, yRange } = axisRanges;
  // TODO: consider not using min/max values in binning
  const binningX = new DataBinning.FiniteDataBinning(xaxis.sectorSize, xRange.min, xRange.max);
  const binningY = new DataBinning.FiniteDataBinning(yaxis.sectorSize, yRange.min, yRange.max);
  const dataBinning = new DataBinning.DataBinning2D(binningX, binningY);

  return dataBinning;
}

function seriesToTracesMapper(serie) {
  let Mapper;
  let mapperConf;
  let trace;
  let traces = [];
  let traceConf = {};

  if (!serie || !serie.hasOwnProperty('type')) {
    throw new TypeError('Serie needs type !!!');
  }

  switch (serie.type) {
    case 'heatmap':
      Mapper = Mappers.SeriesMappers.PlotlyHeatmapSeriesMapper;
      traceConf.type = 'heatmap';
      break;
    case 'scatter':
      Mapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
      traceConf.type = 'scatter';
      break;
    case 'line':
      Mapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
      traceConf.type = 'lines';
      break;
    case 'bar':
      Mapper = Mappers.SeriesMappers.PlotlyBarSeriesMapper;
      traceConf.type = 'bar';
      break;

    case 'tracking': {
      Mapper = Mappers.SeriesMappers.PlotlyScatterSeriesMapper;
      mapperConf = [undefined, undefined, Traces.generic.TrackingTrace];
      break;
    }

    default:
      throw new TypeError(`${serie.type} Not implemented !!!`);
  }

  if (mapperConf) {
    Mapper = new Mapper(...mapperConf);
  } else {
    Mapper = new Mapper();
  }

  trace = Mapper.map(serie);
  if (Array.isArray(trace)) {
    trace.forEach((item) => {
      let trace = mapDefaultTraceSettings(item, traceConf);
      traces.push(trace);
    });
  } else {
    trace = mapDefaultTraceSettings(trace, traceConf);
    traces = [trace];
  }

  return traces;
}

function defaultSeriesMapper(chartContext, chartType, collection, config) {
  const canWorkWith = [
    'batting-strike-zone-heatmap',
    'batting-spray-chart-scatter',
    'pitching-release-point-heatmap',
    'pitching-release-extension-heatmap',
    'pitching-pitch-location-heatmap',
    'pitching-pitch-movement-heatmap',
    'pitching-pitchers-performance-bar',
    'pitching-pitchers-repeatability-bar',
    'batting-batters-performance-bar',
    'batting-plate-discipline-bar',
    'batting-performance-tracking-tracking',
    'pitching-performance-tracking-tracking',
    'pitching-plate-discipline-bar',
  ];
  const chartAndType = `${chartContext}-${chartType}`;

  if (!canWorkWith.includes(chartAndType)) {
    throw new TypeError(`${chartAndType} Not implemented !!!`);
  }

  let tracesObj = { pre: [], data: [], post: [] };
  let traces;

  traces = collection.reduce((acc, curr) => {
    if (!curr) return acc;
    let series = seriesToTracesMapper(curr);

    series = customizeTraceByContext(chartContext, series, config);

    if (!curr.pos) {
      curr.pos = 'data';
    }
    acc[curr.pos] = acc[curr.pos].concat(series);
    return acc;
  }, tracesObj);

  return [...traces.pre, ...traces.data, ...traces.post];
}

function initialSeriesConverter(chartContext, initialSeries, targetSystem, convertDimensions, config = {}) {
  if (convertDimensions && convertDimensions.length) {
    let chartSeries = new Entities.ChartSeries([], initialSeries.getCategories());

    let enumObj;

    switch (chartContext) {
      case 'batting-batters-performance':
        enumObj = Object.assign({}, Enums.BattingStats, Enums.HitData);
        break;
      default:
        enumObj = Enums.PitchData;
    }

    let { type, pos } = initialSeries;
    if (type) {
      chartSeries.type = type;
    }
    if (pos) {
      chartSeries.pos = pos;
    }

    let { baseOnParams = [] } = config;

    return initialSeries.reduce((convertedChartSeries, current) => {
      const currentSeries = current;
      convertedChartSeries.push(
        current.reduce((series, point, pointIndex) => {
          let convertedPoint;
          const source = currentSeries.getSource(pointIndex);
          if (!Array.isArray(point)) {
            if (pointIndex < baseOnParams.length) {
              convertedPoint = {};
              let parameter = Providers.EnumValueProvider.getValue(
                baseOnParams[pointIndex],
                enumObj,
                'NUMBER',
                false,
                'type',
              );
              forOwn(point, (value, key) => {
                if (key !== 'percent') {
                  convertedPoint[key] = convertValue(value, parameter, targetSystem).value;
                } else {
                  convertedPoint[key] = value;
                }
              });
              convertedPoint['unit'] = convertValue(0, parameter, targetSystem).targetUnit.symbols[0];
            } else {
              convertedPoint = point;
            }
            series.push(convertedPoint);
          } else {
            convertedPoint = [];
            for (let d = 0; d < point.length; d++) {
              const value = point[d];
              const convertedObj = convertValue(value, convertDimensions[d].conversionType, targetSystem);
              convertedPoint.push(convertedObj.value);
            }
            series.add(source, ...convertedPoint);
          }

          return series;
        }, new Entities.Series(current.name, current.dimensions)),
      );
      return convertedChartSeries;
    }, chartSeries);
  }

  return initialSeries;
}

function additionalSeriesGetter(context, strikeZoneModel) {
  let series = new Entities.ChartSeries();

  switch (context) {
    case 'batting-strike-zone': {
      let strikeZoneOutline = Providers.StrikeZoneOutlineSeriesProvider(strikeZoneModel, false);
      let sectors = Providers.StrikeZoneSectorsGridSeriesProvider(strikeZoneModel, false);
      series = new Entities.ChartSeries([strikeZoneOutline, sectors], ['strikeZone', 'strikeZoneSectors']);
      series.type = 'line';
      series.pos = 'pre';
      series.hover = 'none';
      break;
    }

    case 'pitching-pitch-location': {
      let strikeZoneOutline = Providers.StrikeZoneOutlineSeriesProvider(strikeZoneModel, false);
      let sectors = Providers.StrikeZoneSectorsGridSeriesProvider(strikeZoneModel, false);
      series = new Entities.ChartSeries([strikeZoneOutline, sectors], ['strikeZone', 'strikeZoneSectors']);
      series.type = 'line';
      series.pos = 'pre';
      series.hover = 'none';
      break;
    }

    case 'pitching-release-point':
    case 'pitching-release-extension':
    case 'pitching-pitch-movement':
    case 'batting-spray-chart':
      // NOOP
      series.type = 'scatter';
      break;

    case 'pitching-pitchers-performance':
    case 'pitching-pitchers-repeatability':
    case 'batting-batters-performance':
    case 'batting-plate-discipline':
    case 'pitching-plate-discipline':
      // TODO: is it needed here?
      series.type = 'bar';
      series.orientation = 'h';
      return undefined;

    case 'batting-performance-tracking':
    case 'pitching-performance-tracking':
    case 'pitching-pitch-location-2':
    case 'batting-strike-zone-2':
      return undefined;

    default:
      throw new TypeError(`${context}: Not implemented !!!`);
  }

  return series;
}

class ChartDataProvider {
  #injectedSeriesProvider;

  #injectedPropsProvider;

  #injectedFilterGuard;

  #injectedSeriesMapper;

  conf;

  stats;

  disableConverting = false;

  series;

  converted;

  unitSystem = measurementSystem.METRIC;

  mapped;

  /**
   *
   * @param {String} chartContext
   * @param {Object} chartConf
   * @param {Object} chartProviders
   */
  constructor(
    chartContext,
    chartConf,
    { seriesProvider = undefined, propsProvider = undefined, filterGuard = undefined, seriesMapper = undefined } = {
      seriesProvider,
      propsProvider,
      filterGuard,
      seriesMapper,
    },
    // chartLib = 'plotly',
  ) {
    this.chartContext = chartContext;

    this.setConf(chartConf);

    if (typeof seriesProvider === 'function') {
      this.injectedSeriesProvider = seriesProvider;
    }

    if (typeof propsProvider === 'function') {
      this.injectedPropsProvider = propsProvider;
    }

    if (typeof filterGuard === 'function') {
      this.injectedFilterGuard = filterGuard;
    }

    if (typeof seriesMapper === 'function') {
      this.injectedSeriesMapper = seriesMapper;
    }
  }

  get convert() {
    return (
      !this.disableConverting &&
      this.convertDimensions &&
      Array.isArray(this.convertDimensions) &&
      this.convertDimensions.length
    );
  }

  get axisUnit() {
    if (this.convertDimensions && this.convertDimensions.length) {
      return this.convertDimensions.map((dimension) => unitSymbolProvider(dimension.conversionType, this.unitSystem));
    }
    return undefined;
  }

  setConf(chartConf) {
    let { chartType, axisContext, disableConverting, convertDimensions, ...conf } = chartConf;

    if (chartType) {
      this.chartType = chartType;
    }

    if (axisContext) {
      this.axisContext = axisContext;
    }

    if (convertDimensions && Array.isArray(convertDimensions) && convertDimensions.length) {
      this.convertDimensions = convertDimensions;
    }

    if (disableConverting) {
      this.disableConverting = disableConverting;
    }

    if (conf) {
      this.conf = conf;
    }
  }

  getAdditionalSeries(strikeZoneModel) {
    return additionalSeriesGetter(this.chartContext, strikeZoneModel);
  }

  seriesMapper(collection) {
    if (collection === undefined) {
      return [];
    }
    if (typeof this.injectedSeriesMapper === 'function') {
      return this.injectedSeriesMapper(this.chartContext, this.chartType, collection, this.conf);
    }
    return defaultSeriesMapper(this.chartContext, this.chartType, collection, this.conf);
  }

  filterGuard(filters) {
    if (typeof this.injectedFilterGuard === 'function') {
      return this.injectedFilterGuard(this.chartContext, filters);
    }
    return contextFilterGuard(this.chartContext, filters);
  }

  getSeries(data, filters, { providerConfig, providerAdditionalParams }) {
    if (typeof this.injectedSeriesProvider === 'function') {
      return this.injectedSeriesProvider(this.chartContext, data, filters, {
        providerConfig,
        providerAdditionalParams,
      });
    }
    return initialSeriesProvider(this.chartContext, data, filters, { providerConfig, providerAdditionalParams });
  }

  /**
   *
   * @param {Array} data
   * @param {Options} options
   */
  getProps(data, options) {
    let {
      axisSettings, axisRanges, convertDimensions, conf, axisUnit,
    } = this;

    if (typeof this.injectedPropsProvider === 'function') {
      return this.injectedPropsProvider(
        this.chartContext,
        data,
        {
          axisSettings, axisRanges, convertDimensions, conf, axisUnit
        },
        options
      );
    }
    return contextPropsProvider(
      this.chartContext,
      data,
      {
        axisSettings, axisRanges, convertDimensions, conf, axisUnit
      },
      options
    );
  }

  getSeriesStats(initialSeries, convertDimensions) {
    return calculateSeriesStats(this, initialSeries, convertDimensions);
  }

  getInitialSeries(data, filters, options) {
    if (!this.filterGuard(filters)) {
      return undefined;
    }

    let {
      isMetric = false, unitSystem = measurementSystem.METRIC, providerConfig, providerAdditionalParams
    } = options;

    this.unitSystem = unitSystem;

    let series = this.getSeries(data, filters, { providerConfig, providerAdditionalParams });

    if (this.convert) {
      series = this.seriesConverter(series, unitSystem);
    }

    series.type = 'scatter';
    series.pos = 'data';

    let strikeZoneModel = new GenericStrikeZone();

    this.stats = this.getSeriesStats(series, this.convertDimensions);

    if (![ChartTypes.BAR, ChartTypes.TRACKING].includes(this.chartType)) {
      this.axisSettings = defaultAxisSettingsProvider(this, isMetric, strikeZoneModel);

      this.axisRanges = this.getAxisRanges(this.axisSettings, this.stats);
    }

    if (ChartTypes.TRACKING === this.chartType) {
      series.type = 'tracking';
      series.pos = 'data';
    }

    if (this.chartType === ChartTypes.BAR) {
      series.type = 'bar';
      series.pos = 'data';
    } else if (this.chartType === ChartTypes.HEATMAP) {
      let binning = this.getBinning(this.axisSettings, this.axisRanges);

      ({ series } = Providers.HeatMapSeriesProvider(series, binning, undefined));

      series.type = 'heatmap';
      series.pos = 'data';
    }

    let additionalSeries = this.getAdditionalSeries(strikeZoneModel);
    if (additionalSeries !== undefined && this.convert) {
      additionalSeries = this.seriesConverter(additionalSeries, unitSystem);
    }

    if (additionalSeries !== undefined) {
      return [series, additionalSeries];
    }

    return [series];
  }

  /**
   *
   * @param {Array} data
   * @param {Object} filters
   * @param {Object} options
   */
  getMappedSeries(data, filters, options) {
    /**
     * @var {ChartSeries[]>} collection
     */
    let collection = this.getInitialSeries(data, filters, options);

    if (collection !== undefined) {
      let mappedSeries = this.seriesMapper(collection);
      return mappedSeries;
    }

    return [];
  }

  getAxisRanges(axisSettings, stats) {
    return defaultAxisRangesProvider(this, axisSettings, stats);
  }

  getBinning(axisSettings, axisRanges) {
    if (this.chartType !== ChartTypes.HEATMAP) {
      return undefined;
    }

    return defaultBinningProvider(this, axisSettings, axisRanges);
  }

  seriesConverter(initialSeries, targetSystem) {
    if (initialSeries.length) {
      if (this.injectedSeriesConverter) {
        return this.injectedSeriesConverter(
          this.chartContext,
          initialSeries,
          targetSystem,
          this.convertDimensions,
          this.conf,
        );
      }
      return initialSeriesConverter(this.chartContext, initialSeries, targetSystem, this.convertDimensions, this.conf);
    }
    return initialSeries;
  }
}

export default ChartDataProvider;
